
/*******************
* NADPISY
*******************/

h1.cvi {
	font-size: rem(40px);
	padding: 5px 0 0 0;
	line-height: 1.1;
	margin: 0;
	font-weight: normal;
	color: #303030;
	display: inline-block;
	text-align: center;

	strong {
		font-weight: bold;
		display: inline-block;
	}

	span{
		font-size: rem(18px);
		display: block;
		margin-top: 6px;
	}

	@include bp("> 450px"){
		text-align: left;
		margin: 0 0 0 18px;

		span{
			text-align: right;
		}
	}	

	@include bp("> 600px"){
		font-size: rem(44px);
		margin: 0 0 0 28px;
	}
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
	font-family: $font;
}

h2.cvi,
.poz h2.cist {
	font-size: rem($h2-size);
	color: #303030;
	font-weight: 500;
	margin: 0;
	padding: 8px 0;
	line-height: 1.2;
}

#zahlavi h2.cvi {
	margin: 0 0 20px 0;
	padding: 0;
}

// Hlavní nadpisy na podstránce
#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
	font-size: rem(34px);
	color: #303030;
	
	@include bp("> 1000px"){
		font-size: rem(40px);
		margin: 20px 0 30px 0;
	}
	@include bp("> 1200px"){
		margin: 40px 0 30px 0;
	}
}

h3 {
	.editor &,
	&.cvi,
	&.ud,
	#prohlaseni & {
		font-size: rem($h3-size);
		font-weight: normal;
		margin: 25px 0 5px 0;
		padding: 8px 20px 4px 0;
		line-height: 1.2;
	}
}

h4 {
	font-size: rem($h4-size);
	line-height: 1.2;
}

h4.urd-list {
	font-size: rem(25px);
	padding-top: .75em;
	float: none;
	border-top: 3px #cccccc solid;
	padding-right: 7em;

	+ .udz.urd-list {
		margin-top: -1.6em;
		margin-bottom: 2em;
		float: none;
		text-align: right;

	}

	@include bp("< 1024px") {
		padding-right: 0;
		
		+ .udz.urd-list {
				margin-top: 1em;
		}
	}

}

h5 {
	font-size: 1.1em;
}