
/*************************
* SEZNAMY
*************************/

/*************************
* MIXINY PRO ODRÁŽKY
*************************/

@mixin bullet($bg: #BCBCBC, $border: $bg, $top: -.08em) {
	&::before {
		@extend %border-box;
		position: relative;
		top: $top;
		margin-right: 7px;
		display: inline-block;
		content: encode-svg('<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="7px" height="7px" viewBox="0 0 7 7"><path fill="#{$bg}" stroke="#{$border}" stroke-miterlimit="10" d="M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z"/></svg>');
		vertical-align: middle;
		line-height: 0;
		overflow: hidden;

		@content;
	}
}

@mixin no-bullet {
	&::before {
		content: normal !important;
	}
}


@mixin dlazdice-ul {
	display: flex;
	flex-wrap: wrap;
	margin: 0 -13px 35px -13px;
	list-style-type: none;
	padding: 0;
	text-align: center;

	body.leftpanel & li {
		@include grid(740px 2 0, 800px 1, 900px 2, 1024px 3);
	}

	#titul & li, 
	body.noleftpanel & li {
		@include grid(650px 2 0, 880px 3, 1024px 4);
	}

	li {
		box-sizing: border-box;
		padding: 12px 13px;
		background-color: #ffffff;
		border: 2px transparent solid;
		background-clip: padding-box;
		margin-top: 0;
		margin-bottom: 0;
		text-align: left;
		position: relative;
		@include grid(550px 2 0, 800px 3, 1024px 4);

		strong + div {
			font-style: normal;
		}

		> strong:first-child {
			font-weight: normal;

			a {
				font-weight: bold;
				font-family: $font;
				font-size: rem(23px);
				display: block;
				padding: 12px 28px;

				&::before {
					content: "";
					@include fill;
				}
			}

			@include link {
				border-top: 2px #99cdcc solid;
				color: #008381;
				text-decoration: none;
				background-color: #f6f6f6;
			}

			@include link-over {
				background-color: #008381;
				border-top-color: #008381;
				color: #ffffff;
			}

			// @include link-over {
			// 	text-decoration: underline;
			// }
		}
	}
}


/************************/

li {
	.odkazy .ui & li li,
	.aktuality2 &.bezobr li li,
	.utvary .ui &.o li li,
	.kategorie .ui & li li,
	.utvary .ui & li li,
	.galerie .ui & li li {
		@include bullet;
	}

	&.u,
	&.typsouboru {
		@include no-bullet;
		list-style-type: none !important;
	}

	.odkazy ol &::before {
		@include no-bullet;
	}
}

dl dt {
	font-size: rem(19px);
	color: $list-top-level-link-color;
	line-height: 1.1;
}

dl.kontakty .utvary {
	padding-top: 4px;
}

.ui {
	li > strong:first-child {
		font-family: $font;
		font-size: rem(23px);

		@include link {
			color: $list-top-level-link-color;
		}

		.utvary &,
		.souvisejiciodkazy & {
			font-family: $sans-serif;
			font-size: rem(16px);
			font-weight: bold;

			@include link {
				color: $color-base-link;
			}
		}

		img {
			font-size: rem(14px);
		}
	}

	li li > strong:first-child {
		.utvary &,
		.bodkazy &,
		.souvisejiciodkazy & {
			font-weight: normal;
		}
	}

	ul li > strong:first-child {
		font-family: $sans-serif;
		font-size: rem(16px);

		@include link {
			color: $color-base-link;
		}
	}

	li {
		clear: none;
		padding: 0;
		margin: 0 0 5px 0;

		strong {
			font-weight: normal;
			line-height: 1.2;

			dl.kontakty .utvary &,
			#stromutvaru .utvary &,
			.odkazy & {
				+ div {
					padding: 0;
					color: #666666;
					line-height: 1.4;
					font-style: italic;
				}
			}

			dl.kontakty .utvary & + div {
				margin-bottom: 10px;
			}
		}

		.ui {
			padding-top: 0;
			padding-bottom: 10px;

			.ui {
				padding-bottom: 0;
				
				.ui {
					padding-left: 18px;
				}
			}
		}

		li {
			margin: 5px 0;

			&::after {
				content: normal;
			}
		}
	}

	ol li {
		padding-left: 0;
	}

	div {
		font-weight: normal;
		margin-bottom: 2px;
		margin-top: 6px;
		padding-top: 0;
		padding-bottom: 0;
	}
}


dl.kontakty .utvary {
	ul.ui {
		margin-left: 0;
		margin-bottom: 15px;
	}

	li {
		strong {
			font-size: rem(15px);
			font-family: $sans-serif;

			@include link {
				color: $color-base-link;
			}
		}
	}
}


/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/

#isvs .hodnota {
	@extend .b2;

	li {
		@include no-bullet;
	}
}


/*********************
* VÝPISY DOKUMENTŮ
*********************/

.dok {
	ul {
		@extend %reset-list;
	}

	li {
		display: block;
		margin: 20px 0;
		padding: 0;

		&:first-child {
			margin-top: 8px;
		}
	}

	.n5-akce-typ,
	.dok-doplnek,
	.ktg,
	strong + span,
	.vd-priloha {
		font-size: rem(15px);
		color: #444444;

		@include link {
			color: #444444;
		}
	}

	.vd-priloha {
		font-family: $sans-serif;
		white-space: nowrap;
	}

	strong {
		font-weight: normal;

		img {
			margin: 3px 20px 6px 0;
			float: left;
		}

		a {
			font-size: rem(22px);
			font-family: $font;
			color: $document-link-color;
			line-height: 1.375;
		}
	}
}

#zpravodajstvi {
	ul.inline {
		padding: 0;
		margin: 20px 0;
	}
}


#kalendarAkci .dok {
	li.u {
		padding-left: 150px;

		strong img {
			margin-left: -150px;
		}
	}
}


/** DOKUMENTY NA TITULCE **/

#pozicovani {
	padding: 70px 0 45px;
	overflow: hidden;

	.poz {
		margin-bottom: 25px;
	}

	@include bp(">= 650px") {
		.skryty {
			display: none;
		}
	}
}

#pozicovani, .aktuality{
	.cards {
		ul {
			//@include dynamic-grid(3, (15px, 1024px 25px));
			@extend %reset-list;
		}

		li {
			margin-top: 0;
			box-sizing: border-box;
			padding-top: 0;
			padding-bottom: 10px;
			bottom: 0;
			@include grid(600px 2 6%, 1000px 2 10%);
		}

		.card {
			@extend %card;
		}
	}
}


/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/

.obrodkazy {
	.ui {
		@extend %reset-list;
	}

	li {
		@include no-bullet;
		@extend %reset;
		display: block;
		margin-bottom: 13px;
		background-image: none;
		position: relative;
	}

	a { 
		font-size: rem(20px);
		font-family: $sans-serif;
		background-repeat: no-repeat;
		background-position: 0 50%;
		padding-left: 65px;
		display: flex;
		align-items: center;
		justify-content: flex-start;
		min-height: 48px;
	}

	strong {
		font-weight: normal;
	}

	@include link {
		text-decoration: none;
	}

	@include link-over {
		text-decoration: underline;
	}
}


/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/

#vypisakci {
	h3.cvi {
		font-size: rem(25px);
		margin-bottom: 10px;
	}

	.dok {
		li {
			display: flex;
			margin: 14px 0;
			padding-bottom: 14px;
			width: 100%;

			strong {
				@include link {
					color: $event-link-color;
				}
			}
		}

		.datum,
		.prazdnyDatum {
			width: 5.52em;
			text-align: center;
			flex-shrink: 0;
			padding: .26em 0;
			font-family: $font;
		}

		.den,
		.mesic {
			display: block;
			line-height: 1.2;
		}

		.den {
			font-size: rem($event-day-size);
			font-weight: bold;
			background: $event-day-background;
			color: $event-day-text;
			padding: .3em 0;
		}

		.mesic {
			font-size: rem($event-month-size);
			background: $event-month-background;
			color: $event-month-text;
			text-transform: lowercase;
			padding: .5em 0;
		}

		.denPredlozka,
		.rok, 
		.den .tecka {
			position: absolute;
			left: -9999px;
			right: 9990px;
		}

		.prazdnyDatum {
			background: transparent;
		}

		.obsahAkce {
			padding-right: 15px;
			padding-left: 15px;
			flex-grow: 1;
		}
	}
}



/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/

.titulodkazy {
	.ui {
		@extend %reset-list;
	}

	li {
		@include no-bullet;
		display: block;
		background-image: none;
		margin: 8px 0 12px;
		padding: 0;
		position: relative;

		a {
			font-size: rem(17px);
		}

		strong:first-child {
			@include link {
				color: #626262;
			}
		}

		div {
			margin: 2px 0 0 0;
		}
	}

	strong {
		font-weight: normal;
	}
}


.dok li.urd-line {
	position: relative;
	margin: 0;
	padding: 15px 20px;

	&:nth-child(even) {
		background-color: #ededed;
	}

	&::after {
		content: normal;
	}

	.urd-left {
		position: relative;
	}

	strong a::after {
		@include fill;
		content: "";
	}

	@include bp("> 800px") {
		display: table;
		box-sizing: border-box;
		width: 100%;
	
		.urd-left,
		.urd-right {
			display: table-cell;
		}
		
		.urd-left {
			padding-right: 1.5em;
		}

		.urd-right {
			width: 13em;
		}
	}
}

.bodkazy .ui li > strong:first-child {
	@include link {
		color: #444444;
	}
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/

@if $bool_dlazdice == true {
	.mapa-stranek-2016 .odkazy.souvisejici ul {
		@include dlazdice-ul;
	}
}