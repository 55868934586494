
/*************************
* ZÁHLAVÍ
*************************/


/*************************
* HLAVA
*************************/
 
#obalhlava {
	position: relative;
	z-index: 13;
	background-color: #ffffff;
	color: #626262;

	@include link {
		color: #626262;
	}
} 

#hlava {
	@extend %sf;
	clear: both;
	position: relative;
	z-index: 3;
}

#znak {
	margin: 0;
}


#nazev {
	text-align: center;
	position: relative;
	margin: 4px 0 12px;
	padding: 5px 0 12px;
	border-bottom: 1px solid #626262;
	box-sizing: border-box;
	width: 100%;

	@include bp("> 1200px"){
		margin: 20px 0 24px 0;	
	}

	a {
		z-index: 30;
		display: inline;
		text-decoration: none !important;
		outline-width: 0;
		font-size: 0;

		&::after {
			content: "";
			@extend %fill;
		}
	}

	img {
		font-size: rem(16px);
		line-height: 1;
		position: relative;
		top: 10px;
		display: none;
	}
}

@include bp("> 450px") {
	#nazev {
		text-align: left;
		display: inline-block;
		vertical-align: middle;

		img{
			display: inline-block;
		}
	}
}

@include bp("> 600px") {
	#nazev {
		padding: 0;
		margin: 4px 0;
		border: 0;
		width: 390px;
	}
}		


/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/

#obrazek {
	display: none;
}

#obalobrazek{
	position: relative;
	max-width: 1920px;
	margin: 0 auto;
}

@include bp("> 600px") {
	#obrazek {
		display: block;
		clear: both;
		position: relative;
		padding-bottom: 38%;
		// bez blenderu:
		background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
		background-size: cover;

		@include bp("> 1000px"){
			padding-bottom: ratio(1920px,590px);
		}

		@include bp("> 1920px"){
			padding-bottom: 590px;
		}	
	}

	.triangle{
		position: absolute;
		max-width: 838px;
		width: 100%;
		height: 80px;
		background: url(images/triangle.png) no-repeat center bottom; 
		background-size: contain;
		left: 50%;
		bottom: -20px;
		transform: translateX(-50%);
		z-index: 9;

		@include bp("> 800px") {	
			bottom: -2px;
		}	
	}

	#administraceobrazku {
		position: absolute;
		left: 50%;
		top: 70%;
		transform: translateX(-50%);
	 	z-index: 4;
	}
}

#blender {
	margin: 0 auto;
	position: relative;
	z-index: 2;
	overflow: hidden;
	background: #303030 url(images/blenderImages/0.jpg##{$cache-hash}) no-repeat 50% 50%;
	background-size: cover;
	@include fill;
}

.blender__buttons {
	position: absolute;
	z-index: 3;
	right: 1em;
	bottom: 1em;
}

.blender__button {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	background: transparent none;
	box-shadow: none;

	&:focus {
		box-shadow: none;
	}
}


@keyframes blend-in {
	from {
		display: block;
		opacity: 0;
	  } to {
	  display: block;
		opacity: 1;
	}
}

.blender__item {
	background: {
		position: 50% 50%;
		repeat: no-repeat;
		size: cover;
	}
}

.blender__item {
	@include fill;
}

.blender__item.blender__item--animating {
	animation: blend-in 800ms ease-out;
	animation-fill-mode: forwards;
}

.hledaniGteAbo{
	text-align: center;
	padding: 2px 0 2px;

	> a{
		padding: 2px 10px;
	}

	@include bp("> 600px"){
		float: right;
		text-align: left;

		> div{
			padding: 0;
		}
	}
}

#lista {
	@extend %sf;
	text-align: right;
	padding: 10px 0;
	position: relative;
	background: #f2f2f2;

	ul {
		@extend %reset-list;
		position: relative;

		&.inline {
			display: inline;
		}
	}

	li {
		margin: 0;
		white-space: nowrap;

		&.skip {
			position: absolute;
			display: block;
			top: 0 !important;
			right: 0 !important;
			max-width: 100%;
			padding: 1px;
			border: 0;

			a {
				text-align: center;
				border: 0;
				position: absolute;
				right: 0;
				top: -500px;
				z-index: 0;
				padding: 0 2px;
				background-color: #ffffff;
				color: #000000;
			}
			
			a:focus,
			a:active {
				z-index: 10 !important;
				position: absolute !important;
				right: 10px;
				top: 5px;
			}
		}
	}
}
