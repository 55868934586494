/******************
* MODULY
******************/

#uvodtitul {
   overflow: hidden;
   margin: 0 0 30px 0;
}

#obalUpozorneni {
   padding: 9px 20px;
   background-color: rgba(#000000, 0.6);
   width: 100%;
   max-width: 1420px;
   text-align: center;
   z-index: 10;
   box-sizing: border-box;

   @include bp('> 600px') {
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
   }

   @include bp('> 1420px') {
      top: 30px;
   }
}

#upozorneni {
   &,
   h2.cvi,
   #{selector-link()} {
      color: white;
   }

   h2.cvi,
   p {
      display: inline-block;
      font-size: rem(17px);
      vertical-align: middle;
   }

   h2.cvi {
      font-weight: bold;
      margin: 0;
      position: relative;
      top: -20px;

      span {
         display: none;
      }

      @include bp('> 500px') {
         &::before {
            content: '';
            width: 65px;
            height: 56px;
            margin-right: 18px;
            position: relative;
            top: 20px;
            background: url(images/pozor.png) no-repeat 0 0;
            display: inline-block;
         }
      }
   }

   p {
      max-width: calc(100% - 90px);
   }

   @include bp('> 800px') {
      h2.cvi {
         margin: 0 20px 0 0;

         span {
            display: inline;
         }
      }

      p {
         max-width: calc(100% - 210px);
      }
   }
}

.aktuality {
   background-color: $barH;
   padding: 0 0 60px;

   &,
   h2.cvi,
   a {
      color: white;
   }

   h2.cvi {
      margin: 0 0 60px;
      text-align: center;
      font-size: rem(40px);
      font-weight: 500;
      position: relative;
      z-index: 12;
      padding: 32px 0 0;

      @include bp('> 600px') {
         margin: -20px 0 80px;
         padding: 8px 0;
      }

      &::after {
         content: '';
         width: 40px;
         height: 16px;
         position: absolute;
         left: 50%;
         margin-left: -20px;
         bottom: -26px;
         background: url(images/triangleWm.png) no-repeat 0 0;
      }
   }
}

.temata {
   ul,
   li {
      @extend %reset-list;
   }

   ul {
      padding: 0 0 20px;
      margin: -25px 0;
   }

   li {
      @include grid(500px 2 3%, 800px 3 4%, 1100px 4 5%);
      text-align: left;
      margin-top: 25px;
      margin-bottom: 25px;

      strong a {
         color: $barH;
         font-size: rem(20px);
         font-weight: 600;

         @include bp('> 1000px') {
            font-size: rem(23px);
         }
      }

      div {
         margin: 0;
         padding: 10px 0 0;
         font-size: rem(17px);
         font-weight: 300;
         color: #626262;
      }
   }
}

.banneryPraha {
   background: url(images/bannery/bg.jpg) no-repeat center top;
   background-size: cover;

   .centrovany {
      display: flex;
   }

   ul,
   li {
      @extend %reset-list;
   }

   ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-self: center;
      width: 100%;
      max-width: 380px;
      margin: 24px auto;
   }

   li {
      background: white;
      padding: 18px 3.2%;
      box-sizing: border-box;
      position: relative;
      margin: 12px auto;

      a::after {
         content: '';
         @include fill;
      }
   }

   @include bp('> 650px') {
      .centrovany {
         max-width: 584px;
         height: 371px;
      }

      ul {
         max-width: 100%;
         margin: 0;
      }

      li {
         margin: 0;
         padding: 18px 1.2%;
      }

      .prahaEu,
      .visitPraha {
         padding: 18px 3.4%;
         margin-bottom: 24px;
      }
   }

   @include bp('> 1300px') {
      .centrovany {
         max-width: 1420px;
      }

      li {
         padding: 18px 1.2%;
      }

      .prahaEu,
      .visitPraha {
         padding: 18px 1.74%;
         margin-bottom: 0;
      }
   }

   @include bp('> 1420px') {
      li {
         padding: 21px 2%;
      }

      .prahaEu,
      .visitPraha {
         padding: 21px 2.9%;
      }
   }
}

#kalendarAkci {
   .editor {
      margin-top: 40px;
      margin-bottom: 40px;
   }
}

#obalmbannery {
   min-width: 320px;
   background: #ffffff;
   border-top: 1px solid #626262;
}

#mbannery {
   padding: 30px 0;
   color: #000000;
   margin: 0;

   .editor {
      text-align: center;

      @include link-all {
         color: #000000 !important;
      }
   }

   li {
      margin-top: 0;
      margin-bottom: 0;
   }
}

#jmeniny {
   padding: 6px 12px 2px 15px;
   text-align: left;

   * {
      display: inline;
   }
}

.kontaktUradu {
   color: #626262;
   font-size: rem(16px);
   font-weight: 300;
   text-align: center;
   padding: 3px 0 2px;

   @include bp('> 600px') {
      float: left;
      text-align: left;
   }

   strong {
      display: none;
   }

   .udajRadek {
      padding: 0 16px;
   }

   .podnadpis-ku {
      padding-right: 16px;
   }
}

.odkazAbo,
.odkazRss,
.fullscreen-search-anchor {
   display: inline-block;
   vertical-align: top;
   margin-top: -5px;
   color: #626262;

   img {
      position: relative;
      top: 4px;
      margin-left: 7px;
   }
}

#kontakt {
   color: #626262;
   font-size: rem(16px);
   clear: both;

   @include bp('> 1200px') {
      font-size: rem(17px);
   }

   address {
      display: block;
      font-style: normal;
      margin: 0;
      line-height: 1.6;
   }

   strong {
      font-weight: bold;
   }

   .udajRadek {
      display: block;
   }

   .mail-druhy::before,
   .tel-druhy::before {
      display: inline;
      font-weight: bold;
      color: transparent !important;
   }

   .mail-druhy::before {
      content: 'E-mail: ';

      @include bp('> 1000px', '<= 1100px') {
         content: normal;
      }
   }

   .tel-druhy:before {
      content: 'Tel.: ';
   }

   a.podrobny {
      display: inline-block;
      margin: 25px 0 0;
      padding: 0;
      text-decoration: underline;
      text-transform: uppercase;
      font-weight: bold;

      &:hover {
         text-decoration: none;
      }
   }

   .podatelna {
      line-height: 1.6;

      .zvyrazneni {
         font-size: rem(17px) !important;
         color: #626262;
      }

      table {
         width: 100%;
         max-width: 300px;

         td {
            vertical-align: top;
         }
      }

      p {
         margin: 0;
      }
   }

   #titul & {
      .podatelna {
         display: block;
         padding-top: 24px;
      }

      @include bp('> 600px') {
         .uradmc {
            float: left;
            width: 49%;
         }

         .podatelna {
            float: right;
            width: 49%;
            padding-top: 0;
         }
      }

      @include bp('> 1000px') {
         clear: none;

         .uradmc {
            float: left;
            width: 45%;
         }

         .podatelna {
            float: right;
            width: 53%;
         }
      }
   }

   #stranka & {
      .kontaktTretina {
         @include grid(801px 3 2.5%);
      }

      .podatelna {
         display: block;
         padding-top: 24px;

         @include bp('> 800px') {
            padding: 0;
         }
      }
   }
}

#anketa {
   p {
      padding: 0;
      margin: 0 0 1em 0;
   }

   ul {
      list-style-type: none;
      padding: 0;
      margin: 8px 0 5px 0;

      li {
         margin-bottom: 8px;
      }
   }

   .hlas {
      margin-top: 3px;
      z-index: 1;

      div {
         height: 12px;
         background-color: $color-main;
      }
   }
}

#pocitadlo {
   margin: 15px 0 0 0;
   text-align: center;
   padding-bottom: 15px;

   ul.inline {
      padding-left: 0;
      display: inline;

      li span {
         margin: 0 0 0 2px;
      }
   }

   @include bp('< 600px') {
      li {
         display: block !important;

         + li::before {
            content: '';
            margin: 0;
         }
      }
   }
}
