
/*************************
* ŠABLONY
*************************/


/*************************
* DOKUMENTY
*************************/


%card {
   margin-top: 0;
   overflow: hidden;
   flex-grow: 1;

   .dok-datum {
      margin-bottom: 10px;
      font-size: rem(18px);
      
        @include bp("> 1000px"){
            font-size: rem(20px);
        }
    }

   strong a{
        color: white;
        text-decoration: underline;

        #pozicovani &{
            color: $barH;
        }

        &:hover{
            text-decoration: none;
        }
   }

   .vd-priloha{
       color: white;
       font-size: rem(17px);
       padding-bottom: 10px;
       display: inline-block;
       
       #pozicovani &{
            color: $barH;
        }
   }

   .dok-nazev{
        display: block;
        font-weight: 500;
        font-size: rem(21px);
        padding-bottom: 10px;
      
        @include bp("> 1000px"){
            font-size: rem(24px);
        }
   }

   .popis {
        font-size: rem(17px);
   }
}
