@charset "UTF-8";
/*!
  Author: WEBHOUSE, s. r. o.
  Version: 1.2
*/
/** PROMĚNNÉ PRO NASTAVENÍ ECCO **/
/*********************************/
/*! ECCO SASS knihovna
    @version: 1.0.4 */
/*****************************************
* ECCO - GLOBAL VARIABLES
*****************************************/
/*****************************************
* ECCO - FUNCTIONS
*****************************************/
/*****************************************
* ECCO - MIXINS
*****************************************/
/*****************************************
* ECCO - PLACEHOLDERS
*****************************************/
#lista ul, .dok ul, #pozicovani .cards ul, .aktuality .cards ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, .temata ul,
.temata li, .banneryPraha ul,
.banneryPraha li, #menu1 ul, #menu ul, body, .obrodkazy li, .fullscreen-search form, #keskryti, #titul .zalozky li, #stranka #kalakci .mesice {
  margin: 0;
  padding: 0;
}

#lista ul, .dok ul, #pozicovani .cards ul, .aktuality .cards ul, .obrodkazy .ui, .titulodkazy .ui, .zalozky ul, .bezjs ul, .temata ul,
.temata li, .banneryPraha ul,
.banneryPraha li, #menu1 ul, #menu ul {
  list-style-type: none;
}

.fullscreen-search .fullscreen-search-button, .fullscreen-search .fullscreen-search-input {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}

.fullscreen-search .fullscreen-search-button:focus, .fullscreen-search .fullscreen-search-input:focus {
  box-shadow: none;
}

#nazev a::after, .titulformular label.label-skryty::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

#kalakci .calcells td .desc {
  position: absolute;
  left: -9999em;
  top: 0;
  width: 0;
  height: 0;
  line-height: 0;
  overflow: hidden;
  outline: 0;
}

#stred,
#vpravo,
#vlevo, .odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.centrovany::after, #hlava::after, #lista::after, .zalozky ul::after {
  content: "";
  display: table;
  font-size: 0;
  clear: both;
}

/*****************************************
* ECCO - FLEXBOX
*****************************************/
/***********************************
/* HODNOTY BITŮ PRO NASTAVENÍ WEBU 
***********************************/
/*************************
* GLOBÁLNÍ PROMĚNNÉ
*************************/
/************************/
/*************************
* PROMĚNNÉ KOMPONENT
*************************/
/** TYPOGRAFIE **/
/** FORMULÁŘE **/
/** TABULKY **/
/** SEZNAMY **/
/** AKCE **/
/** KALENDÁŘE **/
/** Hodnoty je lepší zadávat v em **/
/** MENU **/
/** ZÁLOŽKY **/
/** PATA **/
/************************/
/***********************************************************
* PROMĚNNÉ BREAKPOINTŮ PRO SNADNĚJŠÍ ZADÁVÁNÍ MIXINU "BP"
***********************************************************/
/**********************************************************/
html {
  line-height: 1.375;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 18px;
}

@media screen and (min-width: 601px) {
  html {
    font-size: 16px;
  }
}

body.editor {
  background-color: #ffffff;
}

.editor h3 {
  font-weight: normal;
  margin: 10px 0;
  padding: 0;
  font-family: "Libre Franklin";
  line-height: 1.2;
}

body,
.editor,
body.editor,
body.editor td,
body.editor th {
  font-size: 1em;
}

body,
.automat {
  color: #444444;
}

a,
a:link,
a:visited {
  color: #232323;
  text-decoration: underline;
}

a:hover,
a:focus,
a:active {
  text-decoration: none;
}

hr,
hr.oddelovac {
  color: #dddddd;
  background-color: #dddddd;
}

body,
input,
button,
textarea,
select {
  font-family: "Libre Franklin";
}

.zvyrazneni,
.vystraha,
.strlistovani strong {
  color: #BD013E;
  background-color: #ffffff;
  padding: 0 3px;
}

.vystraha {
  border-radius: 3px;
}

.zvyrazneni2 {
  background-color: #303030;
  color: #ffffff;
  padding: 0 2px;
}

strong.zprava {
  font-size: 1.1em;
}

/*******************
* NADPISY
*******************/
h1.cvi {
  font-size: 2.5rem;
  padding: 5px 0 0 0;
  line-height: 1.1;
  margin: 0;
  font-weight: normal;
  color: #303030;
  display: inline-block;
  text-align: center;
}

h1.cvi strong {
  font-weight: bold;
  display: inline-block;
}

h1.cvi span {
  font-size: 1.125rem;
  display: block;
  margin-top: 6px;
}

@media screen and (min-width: 451px) {
  h1.cvi {
    text-align: left;
    margin: 0 0 0 18px;
  }
  h1.cvi span {
    text-align: right;
  }
}

@media screen and (min-width: 601px) {
  h1.cvi {
    font-size: 2.75rem;
    margin: 0 0 0 28px;
  }
}

h2.cvi,
h2.cist,
h3.cvi,
h3.ud,
#prohlaseni h3,
h4 {
  font-family: "Libre Franklin";
}

h2.cvi,
.poz h2.cist {
  font-size: 2.5rem;
  color: #303030;
  font-weight: 500;
  margin: 0;
  padding: 8px 0;
  line-height: 1.2;
}

#zahlavi h2.cvi {
  margin: 0 0 20px 0;
  padding: 0;
}

#zahlavi h2.cvi,
#zalozkynadpis h2.cvi {
  font-size: 2.125rem;
  color: #303030;
}

@media screen and (min-width: 1001px) {
  #zahlavi h2.cvi,
  #zalozkynadpis h2.cvi {
    font-size: 2.5rem;
    margin: 20px 0 30px 0;
  }
}

@media screen and (min-width: 1201px) {
  #zahlavi h2.cvi,
  #zalozkynadpis h2.cvi {
    margin: 40px 0 30px 0;
  }
}

.editor h3, h3.cvi, h3.ud,
#prohlaseni h3 {
  font-size: 2rem;
  font-weight: normal;
  margin: 25px 0 5px 0;
  padding: 8px 20px 4px 0;
  line-height: 1.2;
}

h4 {
  font-size: 1.5rem;
  line-height: 1.2;
}

h4.urd-list {
  font-size: 1.5625rem;
  padding-top: .75em;
  float: none;
  border-top: 3px #cccccc solid;
  padding-right: 7em;
}

h4.urd-list + .udz.urd-list {
  margin-top: -1.6em;
  margin-bottom: 2em;
  float: none;
  text-align: right;
}

@media screen and (max-width: 1023px) {
  h4.urd-list {
    padding-right: 0;
  }
  h4.urd-list + .udz.urd-list {
    margin-top: 1em;
  }
}

h5 {
  font-size: 1.1em;
}

/*************************
* ŠABLONY
*************************/
/*************************
* DOKUMENTY
*************************/
#pozicovani .cards .card, .aktuality .cards .card {
  margin-top: 0;
  overflow: hidden;
  flex-grow: 1;
}

#pozicovani .cards .card .dok-datum, .aktuality .cards .card .dok-datum {
  margin-bottom: 10px;
  font-size: 1.125rem;
}

@media screen and (min-width: 1001px) {
  #pozicovani .cards .card .dok-datum, .aktuality .cards .card .dok-datum {
    font-size: 1.25rem;
  }
}

#pozicovani .cards .card strong a, .aktuality .cards .card strong a {
  color: white;
  text-decoration: underline;
}

#pozicovani .cards .card strong a, .aktuality .cards #pozicovani .card strong a {
  color: #be0000;
}

#pozicovani .cards .card strong a:hover, .aktuality .cards .card strong a:hover {
  text-decoration: none;
}

#pozicovani .cards .card .vd-priloha, .aktuality .cards .card .vd-priloha {
  color: white;
  font-size: 1.0625rem;
  padding-bottom: 10px;
  display: inline-block;
}

#pozicovani .cards .card .vd-priloha, .aktuality .cards #pozicovani .card .vd-priloha {
  color: #be0000;
}

#pozicovani .cards .card .dok-nazev, .aktuality .cards .card .dok-nazev {
  display: block;
  font-weight: 500;
  font-size: 1.3125rem;
  padding-bottom: 10px;
}

@media screen and (min-width: 1001px) {
  #pozicovani .cards .card .dok-nazev, .aktuality .cards .card .dok-nazev {
    font-size: 1.5rem;
  }
}

#pozicovani .cards .card .popis, .aktuality .cards .card .popis {
  font-size: 1.0625rem;
}

/**************************
* OBECNÉ
**************************/
html {
  touch-action: manipulation;
}

html,
body {
  background-color: #ffffff;
}

body {
  text-align: center;
}

::-moz-selection {
  background-color: #228be6;
  color: #ffffff;
}

::selection {
  background-color: #228be6;
  color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
  border-radius: 3px;
  box-shadow: 0 0 0 2000px #303030 inset, 0 0 0 3px #303030 !important;
  color: #ffffff !important;
  outline: 0;
}

img {
  color: inherit;
}

/*************************
* HLAVNÍ OBSAH
*************************/
.centrovany {
  width: 94%;
  max-width: 1420px;
  margin: 0 auto;
  text-align: left;
}

#stred,
#vpravo,
#vlevo {
  width: 100%;
}

@media screen and (min-width: 801px) {
  #stred,
  #vpravo,
  #vlevo {
    margin-bottom: 110px;
  }
}

#vpravo,
#vlevo {
  word-wrap: break-word;
}

#stranka #stred {
  padding: 50px 0 0;
}

#stranka #stred .stredUzsi {
  max-width: 750px;
  float: left;
  width: 100%;
  padding-bottom: 60px;
}

@media screen and (min-width: 801px) {
  #stranka #stred .stredUzsi {
    padding-bottom: 0;
  }
}

#stranka #stred.sirsi-stred .stredUzsi {
  max-width: 100%;
}

#stranka #obalcelek {
  max-width: 1920px;
  margin: 0 auto;
  overflow: hidden;
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/
@media screen and (min-width: 801px) {
  #telo {
    display: flex;
    justify-content: space-between;
  }
  #stred {
    width: 63.52113%;
    order: 1;
  }
  #vlevo {
    width: 30.42254%;
    position: relative;
  }
  #vlevo::before {
    content: "";
    position: absolute;
    right: 0;
    width: 800px;
    top: 250px;
    bottom: 0;
    background: #f2f2f2;
  }
}

@media screen and (min-width: 801px) and (min-width: 1001px) {
  #vlevo::before {
    top: 270px;
  }
}

@media screen and (min-width: 801px) and (min-width: 1201px) {
  #vlevo::before {
    top: 290px;
  }
}

/************************
* PRVKY STRÁNKY
************************/
.cestaObal {
  background: #be0000;
  font-size: 0.875rem;
  padding: 26px 0 27px 0;
  margin: 0;
  position: relative;
  color: white;
}

.cestaObal strong {
  display: none;
}

.cestaObal a {
  color: white;
}

.cestaObal .cesta {
  text-align: right;
}

.box {
  padding: 0 15px 10px 15px;
  background: #cccccc;
  margin: 0 0 15px 0;
}

.dalsi {
  font-size: 1rem;
  margin: 15px 0 30px 0;
  padding: 35px 0 0 0;
  text-align: center;
  font-family: "Libre Franklin";
  clear: both;
}

@media screen and (min-width: 1001px) {
  .dalsi {
    font-size: 1.125rem;
  }
}

#pozicovani .dalsi {
  padding: 10px 0 0;
}

.dalsi a {
  display: inline-block;
  padding: 22px 25px;
  text-transform: uppercase;
  text-decoration: none;
  font-weight: 500;
}

.dalsi a:hover {
  text-decoration: underline;
}

#pozicovani .dalsi a {
  background-color: #be0000;
  color: white;
}

.aktuality .dalsi a {
  background-color: white;
  color: #be0000;
}

/************************
* ZÁPATÍ
************************/
#nadpatou {
  padding: 40px 0 80px;
}

#nadpatou,
#nadpatou a, #nadpatou a:link, #nadpatou a:visited {
  color: #626262;
}

#nadpatou h2.cvi {
  color: #be0000;
  font-size: 1.875rem;
  font-weight: 600;
  margin: 0;
  padding: 40px 0 20px;
}

#nadpatou .odkazyDole .boxNp {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 600px) {
  #nadpatou .odkazyDole .boxNp {
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  #nadpatou .odkazyDole .boxNp {
    width: 49%;
    margin-left: 2%;
  }
  #nadpatou .odkazyDole .boxNp:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1001px) {
  #nadpatou .odkazyDole {
    width: 57%;
    float: left;
  }
  #nadpatou #kontakt {
    width: 42%;
    float: right;
  }
}

.nadpatou {
  border-top: 1px solid #bbbbbb;
  padding: 20px 0 60px;
}

@media screen and (min-width: 801px) {
  .nadpatou {
    padding: 50px 0 90px;
  }
}

.nadpatou h2.cvi {
  color: #be0000;
  font-size: 1.875rem;
  font-weight: 600;
  margin: 0;
  padding: 40px 0 20px;
}

#obalpata {
  background-color: #be0000;
  min-width: 320px;
}

#pata {
  text-align: center;
  padding: 22px 16px 28px;
  color: #ffffff;
  box-sizing: border-box;
  font-size: 0.875rem;
}

#pata * {
  line-height: 2.2;
  border: 0;
}

#pata li + li::before {
  content: "\|";
  margin: 0 8px 0 0;
}

#pata .partWebmaster1::before {
  content: "\|";
  margin: 0 10px 0 0;
}

#pata a,
#pata a:link,
#pata a:visited {
  color: #ffffff;
  text-decoration: underline;
}

#pata a:hover,
#pata a:focus,
#pata a:active {
  text-decoration: none;
}

#pata .patalogo {
  padding: 1px 6px;
  margin-right: 11px;
}

#pata #pocitadlo {
  margin: 0 !important;
  padding-bottom: 0 !important;
}

#pata .webmaster,
#pata .inline {
  display: inline-block;
  margin: 0;
  padding: 0 0 0 3px;
}

#pata .webmaster li,
#pata .inline li {
  padding: 0 5px;
}

#pata .webmaster li a,
#pata .inline li a {
  margin: 0 0 0 1px;
}

#pata .webmaster {
  display: block;
}

#stranka #pata .webmaster {
  margin-top: 2px;
}

#pata .webmaster .partWebmaster {
  display: inline-block;
  margin-left: 5px;
}

#pata ul.inline li {
  display: inline-block;
}

@media screen and (max-width: 499px) {
  .partWebmaster1 {
    display: block !important;
    margin-top: 2px;
  }
  .partWebmaster1::before {
    content: "" !important;
    margin: 0 !important;
  }
}

#unpobtekane {
  margin-left: 0 !important;
}

/*************************
* ZÁHLAVÍ
*************************/
/*************************
* HLAVA
*************************/
#obalhlava {
  position: relative;
  z-index: 13;
  background-color: #ffffff;
  color: #626262;
}

#obalhlava a,
#obalhlava a:link,
#obalhlava a:visited {
  color: #626262;
}

#hlava {
  clear: both;
  position: relative;
  z-index: 3;
}

#znak {
  margin: 0;
}

#nazev {
  text-align: center;
  position: relative;
  margin: 4px 0 12px;
  padding: 5px 0 12px;
  border-bottom: 1px solid #626262;
  box-sizing: border-box;
  width: 100%;
}

@media screen and (min-width: 1201px) {
  #nazev {
    margin: 20px 0 24px 0;
  }
}

#nazev a {
  z-index: 30;
  display: inline;
  text-decoration: none !important;
  outline-width: 0;
  font-size: 0;
}

#nazev a::after {
  content: "";
}

#nazev img {
  font-size: 1rem;
  line-height: 1;
  position: relative;
  top: 10px;
  display: none;
}

@media screen and (min-width: 451px) {
  #nazev {
    text-align: left;
    display: inline-block;
    vertical-align: middle;
  }
  #nazev img {
    display: inline-block;
  }
}

@media screen and (min-width: 601px) {
  #nazev {
    padding: 0;
    margin: 4px 0;
    border: 0;
    width: 390px;
  }
}

/****************************
* HLAVNÍ OBRÁZEK A BLENDER
****************************/
#obrazek {
  display: none;
}

#obalobrazek {
  position: relative;
  max-width: 1920px;
  margin: 0 auto;
}

@media screen and (min-width: 601px) {
  #obrazek {
    display: block;
    clear: both;
    position: relative;
    padding-bottom: 38%;
    background: #303030 url(images/blenderImages/0.jpg#u6da2e7b1) no-repeat 50% 50%;
    background-size: cover;
  }
}

@media screen and (min-width: 601px) and (min-width: 1001px) {
  #obrazek {
    padding-bottom: 30.72917%;
  }
}

@media screen and (min-width: 601px) and (min-width: 1921px) {
  #obrazek {
    padding-bottom: 590px;
  }
}

@media screen and (min-width: 601px) {
  .triangle {
    position: absolute;
    max-width: 838px;
    width: 100%;
    height: 80px;
    background: url(images/triangle.png) no-repeat center bottom;
    background-size: contain;
    left: 50%;
    bottom: -20px;
    transform: translateX(-50%);
    z-index: 9;
  }
}

@media screen and (min-width: 601px) and (min-width: 801px) {
  .triangle {
    bottom: -2px;
  }
}

@media screen and (min-width: 601px) {
  #administraceobrazku {
    position: absolute;
    left: 50%;
    top: 70%;
    transform: translateX(-50%);
    z-index: 4;
  }
}

#blender {
  margin: 0 auto;
  position: relative;
  z-index: 2;
  overflow: hidden;
  background: #303030 url(images/blenderImages/0.jpg#u6da2e7b1) no-repeat 50% 50%;
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__buttons {
  position: absolute;
  z-index: 3;
  right: 1em;
  bottom: 1em;
}

.blender__button {
  border-radius: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent none;
  box-shadow: none;
}

.blender__button:focus {
  box-shadow: none;
}

@keyframes blend-in {
  from {
    display: block;
    opacity: 0;
  }
  to {
    display: block;
    opacity: 1;
  }
}

.blender__item {
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
}

.blender__item {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.blender__item.blender__item--animating {
  animation: blend-in 800ms ease-out;
  animation-fill-mode: forwards;
}

.hledaniGteAbo {
  text-align: center;
  padding: 2px 0 2px;
}

.hledaniGteAbo > a {
  padding: 2px 10px;
}

@media screen and (min-width: 601px) {
  .hledaniGteAbo {
    float: right;
    text-align: left;
  }
  .hledaniGteAbo > div {
    padding: 0;
  }
}

#lista {
  text-align: right;
  padding: 10px 0;
  position: relative;
  background: #f2f2f2;
}

#lista ul {
  position: relative;
}

#lista ul.inline {
  display: inline;
}

#lista li {
  margin: 0;
  white-space: nowrap;
}

#lista li.skip {
  position: absolute;
  display: block;
  top: 0 !important;
  right: 0 !important;
  max-width: 100%;
  padding: 1px;
  border: 0;
}

#lista li.skip a {
  text-align: center;
  border: 0;
  position: absolute;
  right: 0;
  top: -500px;
  z-index: 0;
  padding: 0 2px;
  background-color: #ffffff;
  color: #000000;
}

#lista li.skip a:focus,
#lista li.skip a:active {
  z-index: 10 !important;
  position: absolute !important;
  right: 10px;
  top: 5px;
}

/*************************
* SEZNAMY
*************************/
/*************************
* MIXINY PRO ODRÁŽKY
*************************/
/************************/
.odkazy .ui li li li::before,
.aktuality2 li.bezobr li li::before,
.utvary .ui li.o li li::before,
.kategorie .ui li li li::before,
.utvary .ui li li li::before,
.galerie .ui li li li::before {
  position: relative;
  top: -0.08em;
  margin-right: 7px;
  display: inline-block;
  content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='7px' height='7px' viewBox='0 0 7 7'%3E%3Cpath fill='%23BCBCBC' stroke='%23BCBCBC' stroke-miterlimit='10' d='M2.214,0.5h2.572C5.732,0.5,6.5,1.268,6.5,2.214v2.572C6.5,5.732,5.732,6.5,4.786,6.5H2.214C1.268,6.5,0.5,5.732,0.5,4.786V2.214C0.5,1.268,1.268,0.5,2.214,0.5z'/%3E%3C/svg%3E");
  vertical-align: middle;
  line-height: 0;
  overflow: hidden;
}

li.u, li.typsouboru {
  list-style-type: none !important;
}

li.u::before, li.typsouboru::before {
  content: normal !important;
}

.odkazy ol li::before::before {
  content: normal !important;
}

dl dt {
  font-size: 1.1875rem;
  color: #be0000;
  line-height: 1.1;
}

dl.kontakty .utvary {
  padding-top: 4px;
}

.ui li > strong:first-child {
  font-family: "Libre Franklin";
  font-size: 1.4375rem;
}

.ui li > strong:first-child a,
.ui li > strong:first-child a:link,
.ui li > strong:first-child a:visited {
  color: #be0000;
}

.utvary .ui li > strong:first-child,
.souvisejiciodkazy .ui li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
  font-weight: bold;
}

.utvary .ui li > strong:first-child a,
.utvary .ui li > strong:first-child a:link,
.utvary .ui li > strong:first-child a:visited,
.souvisejiciodkazy .ui li > strong:first-child a,
.souvisejiciodkazy .ui li > strong:first-child a:link,
.souvisejiciodkazy .ui li > strong:first-child a:visited {
  color: #232323;
}

.ui li > strong:first-child img {
  font-size: 0.875rem;
}

.utvary .ui li li > strong:first-child,
.bodkazy .ui li li > strong:first-child,
.souvisejiciodkazy .ui li li > strong:first-child {
  font-weight: normal;
}

.ui ul li > strong:first-child {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1rem;
}

.ui ul li > strong:first-child a,
.ui ul li > strong:first-child a:link,
.ui ul li > strong:first-child a:visited {
  color: #232323;
}

.ui li {
  clear: none;
  padding: 0;
  margin: 0 0 5px 0;
}

.ui li strong {
  font-weight: normal;
  line-height: 1.2;
}

dl.kontakty .utvary .ui li strong + div,
#stromutvaru .utvary .ui li strong + div,
.odkazy .ui li strong + div {
  padding: 0;
  color: #666666;
  line-height: 1.4;
  font-style: italic;
}

dl.kontakty .utvary .ui li strong + div {
  margin-bottom: 10px;
}

.ui li .ui {
  padding-top: 0;
  padding-bottom: 10px;
}

.ui li .ui .ui {
  padding-bottom: 0;
}

.ui li .ui .ui .ui {
  padding-left: 18px;
}

.ui li li {
  margin: 5px 0;
}

.ui li li::after {
  content: normal;
}

.ui ol li {
  padding-left: 0;
}

.ui div {
  font-weight: normal;
  margin-bottom: 2px;
  margin-top: 6px;
  padding-top: 0;
  padding-bottom: 0;
}

dl.kontakty .utvary ul.ui {
  margin-left: 0;
  margin-bottom: 15px;
}

dl.kontakty .utvary li strong {
  font-size: 0.9375rem;
  font-family: Arial, Helvetica, sans-serif;
}

dl.kontakty .utvary li strong a,
dl.kontakty .utvary li strong a:link,
dl.kontakty .utvary li strong a:visited {
  color: #232323;
}

/**********************************
*	POVINNĚ ZVEŘEJŇOVANÉ INFORMACE
**********************************/
#isvs .hodnota li::before {
  content: normal !important;
}

/*********************
* VÝPISY DOKUMENTŮ
*********************/
.dok li {
  display: block;
  margin: 20px 0;
  padding: 0;
}

.dok li:first-child {
  margin-top: 8px;
}

.dok .n5-akce-typ,
.dok .dok-doplnek,
.dok .ktg,
.dok strong + span,
.dok .vd-priloha {
  font-size: 0.9375rem;
  color: #444444;
}

.dok .n5-akce-typ a,
.dok .n5-akce-typ a:link,
.dok .n5-akce-typ a:visited,
.dok .dok-doplnek a,
.dok .dok-doplnek a:link,
.dok .dok-doplnek a:visited,
.dok .ktg a,
.dok .ktg a:link,
.dok .ktg a:visited,
.dok strong + span a,
.dok strong + span a:link,
.dok strong + span a:visited,
.dok .vd-priloha a,
.dok .vd-priloha a:link,
.dok .vd-priloha a:visited {
  color: #444444;
}

.dok .vd-priloha {
  font-family: Arial, Helvetica, sans-serif;
  white-space: nowrap;
}

.dok strong {
  font-weight: normal;
}

.dok strong img {
  margin: 3px 20px 6px 0;
  float: left;
}

.dok strong a {
  font-size: 1.375rem;
  font-family: "Libre Franklin";
  color: #be0000;
  line-height: 1.375;
}

#zpravodajstvi ul.inline {
  padding: 0;
  margin: 20px 0;
}

#kalendarAkci .dok li.u {
  padding-left: 150px;
}

#kalendarAkci .dok li.u strong img {
  margin-left: -150px;
}

/** DOKUMENTY NA TITULCE **/
#pozicovani {
  padding: 70px 0 45px;
  overflow: hidden;
}

#pozicovani .poz {
  margin-bottom: 25px;
}

@media screen and (min-width: 650px) {
  #pozicovani .skryty {
    display: none;
  }
}

#pozicovani .cards li, .aktuality .cards li {
  margin-top: 0;
  box-sizing: border-box;
  padding-top: 0;
  padding-bottom: 10px;
  bottom: 0;
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 599px) {
  #pozicovani .cards li, .aktuality .cards li {
    width: 100%;
  }
}

@media screen and (min-width: 600px) and (max-width: 999px) {
  #pozicovani .cards li, .aktuality .cards li {
    width: 47%;
    margin-left: 6%;
  }
  #pozicovani .cards li:nth-of-type(2n+1), .aktuality .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1000px) {
  #pozicovani .cards li, .aktuality .cards li {
    width: 45%;
    margin-left: 10%;
  }
  #pozicovani .cards li:nth-of-type(2n+1), .aktuality .cards li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

/*******************************
* OSNOVA S UPOUTÁVKAMI V POZADÍ	
*******************************/
.obrodkazy li {
  display: block;
  margin-bottom: 13px;
  background-image: none;
  position: relative;
}

.obrodkazy li::before {
  content: normal !important;
}

.obrodkazy a {
  font-size: 1.25rem;
  font-family: Arial, Helvetica, sans-serif;
  background-repeat: no-repeat;
  background-position: 0 50%;
  padding-left: 65px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  min-height: 48px;
}

.obrodkazy strong {
  font-weight: normal;
}

.obrodkazy a,
.obrodkazy a:link,
.obrodkazy a:visited {
  text-decoration: none;
}

.obrodkazy a:hover,
.obrodkazy a:focus,
.obrodkazy a:active {
  text-decoration: underline;
}

/*****************************
* VÝPIS AKCÍ S CEDULKAMI
*****************************/
#vypisakci h3.cvi {
  font-size: 1.5625rem;
  margin-bottom: 10px;
}

#vypisakci .dok li {
  display: flex;
  margin: 14px 0;
  padding-bottom: 14px;
  width: 100%;
}

#vypisakci .dok li strong a,
#vypisakci .dok li strong a:link,
#vypisakci .dok li strong a:visited {
  color: orange;
}

#vypisakci .dok .datum,
#vypisakci .dok .prazdnyDatum {
  width: 5.52em;
  text-align: center;
  flex-shrink: 0;
  padding: .26em 0;
  font-family: "Libre Franklin";
}

#vypisakci .dok .den,
#vypisakci .dok .mesic {
  display: block;
  line-height: 1.2;
}

#vypisakci .dok .den {
  font-size: 1.375rem;
  font-weight: bold;
  background: gold;
  color: #101010;
  padding: .3em 0;
}

#vypisakci .dok .mesic {
  font-size: 1rem;
  background: #444444;
  color: #ffffff;
  text-transform: lowercase;
  padding: .5em 0;
}

#vypisakci .dok .denPredlozka,
#vypisakci .dok .rok,
#vypisakci .dok .den .tecka {
  position: absolute;
  left: -9999px;
  right: 9990px;
}

#vypisakci .dok .prazdnyDatum {
  background: transparent;
}

#vypisakci .dok .obsahAkce {
  padding-right: 15px;
  padding-left: 15px;
  flex-grow: 1;
}

/*****************************
* MODUL DALŠÍ ZDROJE NA TITULCE
*****************************/
.titulodkazy li {
  display: block;
  background-image: none;
  margin: 8px 0 12px;
  padding: 0;
  position: relative;
}

.titulodkazy li::before {
  content: normal !important;
}

.titulodkazy li a {
  font-size: 1.0625rem;
}

.titulodkazy li strong:first-child a,
.titulodkazy li strong:first-child a:link,
.titulodkazy li strong:first-child a:visited {
  color: #626262;
}

.titulodkazy li div {
  margin: 2px 0 0 0;
}

.titulodkazy strong {
  font-weight: normal;
}

.dok li.urd-line {
  position: relative;
  margin: 0;
  padding: 15px 20px;
}

.dok li.urd-line:nth-child(even) {
  background-color: #ededed;
}

.dok li.urd-line::after {
  content: normal;
}

.dok li.urd-line .urd-left {
  position: relative;
}

.dok li.urd-line strong a::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  content: "";
}

@media screen and (min-width: 801px) {
  .dok li.urd-line {
    display: table;
    box-sizing: border-box;
    width: 100%;
  }
  .dok li.urd-line .urd-left,
  .dok li.urd-line .urd-right {
    display: table-cell;
  }
  .dok li.urd-line .urd-left {
    padding-right: 1.5em;
  }
  .dok li.urd-line .urd-right {
    width: 13em;
  }
}

.bodkazy .ui li > strong:first-child a,
.bodkazy .ui li > strong:first-child a:link,
.bodkazy .ui li > strong:first-child a:visited {
  color: #444444;
}

/**********************
*** DLAŽDICOVÉ MENU ***
**********************/
/*****************************
* FORMULÁŘE
*****************************/
/*****************************
* PLACEHOLDERY PRO PRVKY
*****************************/
.titulformular label {
  line-height: 1.2;
  text-align: left;
  padding: 3px 2px;
  display: block;
}

.titulformular label.label-skryty {
  padding: 0 2px;
  position: relative;
}

.titulformular label.label-skryty::after {
  content: "";
  z-index: 2;
}

/****************************/
/*************************************************
* VŠECHNY BĚŽNÉ PRVKY VE FORMULÁŘÍCH
*************************************************/
.format {
  background-color: #ffffff;
  color: #000000;
  height: 44px;
  border-radius: 0;
  line-height: 1.21429;
  font-size: 0.9375rem;
}

.format:-ms-input-placeholder {
  color: inherit !important;
}

.format::-ms-input-placeholder {
  color: inherit !important;
}

.format::placeholder {
  color: inherit !important;
  opacity: 1;
}

.format:focus {
  border-color: #437DCC;
}

.format,
fieldset {
  border: 1px #cccccc solid;
}

:not(.fvlevo) > .fvyraz #hv {
  font-size: 1.1875rem;
  height: 44px;
  border-width: 2px;
}

textarea.format {
  min-height: 88px;
}

select.format[multiple] {
  height: 132px;
}

.btn {
  background-color: #be0000;
  border: 0;
  color: #ffffff;
  height: 44px;
}

.btn:hover {
  text-decoration: underline;
}

.fbtn .btn {
  padding: 0 2em;
  font-size: 1rem;
}

/************************************************/
.fkont {
  margin-top: 20px;
}

.fkont form {
  padding: 21px 36px 23px 36px;
}

.fkont :not(.fvlevo) > .fvyraz label[for="hv"] {
  font-size: 1rem;
}

.formular,
.fkont form,
.nastaveni {
  background-color: #f2f2f2;
  color: #202020;
}

.formular a,
.formular a:link,
.formular a:visited,
.fkont form a,
.fkont form a:link,
.fkont form a:visited,
.nastaveni a,
.nastaveni a:link,
.nastaveni a:visited {
  color: #202020;
}

@media screen and (max-width: 580px) {
  .nizky form {
    padding-bottom: 60px;
  }
}

.fcesta,
.fcesta a, .fcesta a:link, .fcesta a:visited, .fcesta a:hover, selector:focus, selector:active {
  background-color: #be0000;
  color: #ffffff;
}

/*******************************
*	ABO A NAPIŠTE NÁM NA TITULCE
*******************************/
.titulformular label.label-skryty::after {
  background: transparent url(images/frm_prekryti.svg) repeat 0 0;
}

.titulformular .format {
  margin: 2px 0;
  padding: 5px 15px;
  width: 100%;
  border-color: #ffffff;
  background-repeat: no-repeat;
  background-position: 0 0;
}

.titulformular img {
  position: absolute;
  right: 0;
  top: 0;
}

.titulformular .opiste {
  position: relative;
}

.titulformular .captchaformat {
  padding-right: 90px;
}

.titulformular .fbtn,
.titulformular .btn {
  clear: none;
  margin: 0;
}

.titulformular .btn {
  display: inline-block;
}

.titulformular p {
  padding: 0 0 10px 0;
  margin: 0;
}

/***************
*	HLEDÁNÍ
***************/
.inline-form {
  padding-bottom: 3em;
}

.inline-form #skryvaci,
.inline-form-word {
  display: inline-block;
}

.inline-form-search {
  position: relative;
}

.inline-form-search input,
.inline-form-search label,
.inline-form-search textarea,
.inline-form-search select {
  font-size: 1rem;
}

.inline-form-word {
  margin: .25em 0;
}

.inline-form-input {
  box-sizing: border-box;
  max-width: 12em;
  padding: 2px 10px;
}

.inline-form-datepicker {
  padding-right: 30px;
  background-image: url(/aspinclude/vismoWeb5/html/images/abonent_calendar_2016.svg);
  background-size: 18px auto;
  background-repeat: no-repeat;
  background-position: 98% 48%;
  max-width: 8em;
}

.inline-form-input,
.inline-form-select {
  border-radius: 4px;
  border: 1px #888888 solid;
  height: 1.75em;
}

.inline-form .fbtn {
  margin-bottom: -3em;
}

.inline-form-select {
  max-width: 13em;
  padding-right: 25px;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  text-overflow: ellipsis;
  background-image: url("data:image/svg+xml;charset=utf8,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' x='0px' y='0px' width='15px' height='15px' viewBox='0 0 15 15' xml:space='preserve'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23444444' d='M4,0l4,7H0L4,0z M4,15L0,8h8L4,15z'/%3E%3C/svg%3E");
  background-position: 100% 50%;
  background-repeat: no-repeat;
}

.inline-form #prepinac {
  margin-top: 2em;
}

@media screen and (min-width: 601px) {
  .inline-form #prepinac {
    position: absolute;
    left: 0;
    bottom: -2.3em;
    font-size: 1rem;
  }
}

/*******************************
* FULLSCREEN-SEARCH
*******************************/
.fullscreen-search-anchor {
  z-index: 5;
  white-space: nowrap;
}

.fullscreen-search-anchor, .fullscreen-search-anchor:link, .fullscreen-search-anchor:visited {
  text-decoration: underline;
}

.fullscreen-search-anchor:hover, .fullscreen-search-anchor:focus, .fullscreen-search-anchor:active {
  text-decoration: none;
}

.fullscreen-search {
  transition: background-color .1s ease-out;
  position: fixed;
  top: 0;
  left: -999em;
  z-index: 19000;
  width: .1px;
  height: .1px;
  overflow: hidden;
  text-align: center;
  font-family: "Libre Franklin";
  background: rgba(190, 0, 0, 0.9);
}

.fullscreen-search:target, .fullscreen-search[data-is-shown="true"] {
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  width: auto;
  height: auto;
  backdrop-filter: blur(8px) grayscale(100%);
}

.fullscreen-search form {
  font-size: 1rem;
  width: 80%;
  max-width: 1300px;
  margin: 0 auto;
  padding: 10% 0 0 0;
  text-align: left;
  color: #ffffff;
  line-height: 1.4;
}

@media screen and (min-height: 35em) {
  .fullscreen-search form {
    padding-top: 14%;
  }
}

@media screen and (orientation: portrait) {
  .fullscreen-search form {
    padding-top: 33%;
  }
}

.fullscreen-search .fullscreen-search-close {
  transition: background-color .15s ease-out;
  position: absolute;
  top: 0;
  right: 0;
  width: 72px;
  height: 72px;
  cursor: pointer;
  background: transparent url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='40' height='40' viewBox='0 0 40 40'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' fill='%23fff' d='M21.422 20l15.287 15.287c.393.393.393 1.029 0 1.422s-1.029.393-1.422 0l-15.287-15.287-15.287 15.287c-.393.393-1.029.393-1.422 0s-.393-1.029 0-1.422l15.287-15.287-15.287-15.287c-.393-.393-.393-1.029 0-1.422s1.029-.393 1.422 0l15.287 15.287 15.287-15.287c.393-.393 1.029-.393 1.422 0s.393 1.029 0 1.422l-15.287 15.287z'/%3E%3C/svg%3E") no-repeat 50% 50%;
}

.fullscreen-search .fullscreen-search-close:hover,
.fullscreen-search .fullscreen-search-close:focus,
.fullscreen-search .fullscreen-search-close:active {
  background-color: #9a1915;
}

.fullscreen-search .fullscreen-search-label {
  font-size: 1.125rem;
  display: block;
  margin: 0 0 .9em 0;
  color: #ffffff;
  text-transform: uppercase;
  font-weight: 600;
}

.fullscreen-search .pole {
  position: relative;
}

.fullscreen-search .fullscreen-search-button {
  appearance: none;
  border: 0;
  background: transparent;
  color: #ffffff;
  width: 2.5em;
  height: 2.5em;
  position: absolute;
  right: 0;
  top: 50%;
  margin: -1.3em 0 0 0;
}

@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-button {
    width: 5em;
    height: 5em;
    margin: -2.5em 0 0 0;
  }
}

.fullscreen-search .fullscreen-search-input {
  box-sizing: border-box;
  font-size: 2.375rem;
  width: 100%;
  font-family: "Libre Franklin";
  background: transparent;
  color: #ffffff;
  outline: 0;
  font-weight: bold;
  padding: 0 1em 0 0;
  border-width: 0 0 2px 0;
  border-color: rgba(255, 255, 255, 0.8);
  border-style: dotted;
}

.fullscreen-search .fullscreen-search-input:focus {
  box-shadow: none !important;
}

.fullscreen-search .fullscreen-search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

@media screen and (min-width: 601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 2.625rem;
  }
}

@media screen and (min-width: 1025px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5rem;
  }
}

@media screen and (min-width: 1601px) {
  .fullscreen-search .fullscreen-search-input {
    font-size: 5.375rem;
  }
}

/***********************
*	TABULKY
***********************/
.blokposun {
  background-color: #eeeeee;
}

.seznam {
  border: 0;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited,
.seznam th a:hover,
.seznam th a:focus,
.seznam th a:active {
  color: #ffffff;
}

.seznam td,
.seznam th {
  border: 1px white solid;
  border-width: 0 1px 1px 0;
}

.seznam td:first-child,
.seznam th:first-child {
  padding-left: 36px;
}

.seznam td:last-child,
.seznam th:last-child {
  border-right: 0;
}

.seznam th {
  font-size: 1.125rem;
  font-family: "Libre Franklin";
  line-height: 1.1;
  font-weight: normal;
  background-color: #be0000;
  color: #ffffff;
}

.seznam th a,
.seznam th a:link,
.seznam th a:visited {
  color: #ffffff;
}

.seznam td {
  color: black;
  border-width: 1px 1px 0 0;
}

.seznam .b1 td {
  border-color: #ffffff;
}

.seznam .b2 td, .seznam #isvs .hodnota td, #isvs .seznam .hodnota td {
  border-color: #ebebeb;
}

.b1 {
  background-color: #ebebeb;
}

.b2, #isvs .hodnota {
  background-color: #ffffff;
}

/**********************
*	FOTOGALERIE
**********************/
.nahledy li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 349px) {
  .nahledy li {
    width: 100%;
  }
}

@media screen and (min-width: 350px) and (max-width: 599px) {
  .nahledy li {
    width: 49%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 600px) and (max-width: 1023px) {
  .nahledy li {
    width: 32%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .nahledy li {
    width: 23.5%;
    margin-left: 2%;
  }
  .nahledy li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.nahledy div > a {
  background: #ebebeb;
  border: 2px transparent solid;
  border-radius: 0px;
  min-height: 200px;
}

.nahledy div > a:hover,
.nahledy div > a:focus,
.nahledy div > a:active {
  background: transparent;
  border-color: #be0000;
}

/**********************
*	PŘEHLEDY GALERIÍ
**********************/
.galerie-2016 .ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 399px) {
  .galerie-2016 .ui li {
    width: 100%;
  }
}

@media screen and (min-width: 400px) and (max-width: 1023px) {
  .galerie-2016 .ui li {
    width: 49%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1024px) {
  .galerie-2016 .ui li {
    width: 32%;
    margin-left: 2%;
  }
  .galerie-2016 .ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

/***********************
*	ZÁLOŽKY
***********************/
.zalozky {
  font-size: 1.25rem;
  line-height: 1.1;
  font-family: "Libre Franklin";
  font-weight: normal;
}

.zalozky li {
  padding: 0;
  margin: 0 .5em .5em 0;
  float: left;
  position: relative;
  display: block;
}

.zalozky li.azalozka {
  background: transparent none;
}

.zalozky a {
  display: block;
  text-decoration: none;
  margin: 0;
  position: relative;
  z-index: 20;
  cursor: pointer;
  padding: 10px 36px 12px;
}

.zalozky a,
.zalozky a:link,
.zalozky a:visited {
  background-color: #be0000;
  color: #ffffff;
}

.zalozky a:hover,
.zalozky a:focus,
.zalozky a:active {
  text-decoration: underline;
}

.zalozky .azalozka a,
.zalozky .azalozka a:link,
.zalozky .azalozka a:visited,
.zalozky .azalozka a:hover,
.zalozky .azalozka a:focus,
.zalozky .azalozka a:active {
  background-color: white;
  color: #be0000;
  text-decoration: none;
  cursor: default;
}

#zahlavi2 {
  margin: 20px 0;
}

#zalozkynadpis {
  padding: 15px 0 0 0;
}

.vych_pol {
  font-size: 1rem;
  text-align: right;
  padding: 0 0 10px 0;
  margin: 0 0 10px 0;
  text-transform: uppercase;
}

.vych_pol strong {
  font-weight: normal;
}

a[id*="k0"] {
  height: 0;
  overflow: hidden;
  line-height: 0;
  font-size: 0;
  color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/
.bezjs {
  padding: 20px 0 15px 0;
}

.bezjs .inline li::before {
  color: #404040;
}

#keskryti {
  display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/
#titul .zalozky {
  line-height: 1.1;
  height: 288px;
  position: relative;
  background: #303030 url(images/bg_zalozky.jpg) no-repeat center top;
}

#titul .zalozky ul {
  position: absolute;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
  max-width: 1182px;
  width: 100%;
  margin: 0;
  padding: 0;
}

#titul .zalozky .inline li::before {
  content: normal;
}

#titul .zalozky li {
  float: none;
  display: inline-block;
  margin: 0;
  padding: 0 8px;
  box-sizing: border-box;
  font-size: 1.5rem;
  width: 33%;
}

@media screen and (min-width: 801px) {
  #titul .zalozky li {
    padding: 0 16px;
    font-size: 1.875rem;
  }
}

#titul .zalozky a {
  padding: 1.2em 16px;
}

#titul .zalozky a,
#titul .zalozky a:link,
#titul .zalozky a:visited {
  background-color: #be0000;
  color: #ffffff;
}

#titul .zalozky a:hover,
#titul .zalozky a:focus,
#titul .zalozky a:active {
  text-decoration: underline;
}

#titul .zalozky .azalozka a,
#titul .zalozky .azalozka a:link,
#titul .zalozky .azalozka a:visited,
#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:focus,
#titul .zalozky .azalozka a:active {
  background-color: white;
  color: #be0000;
  font-weight: 600;
  text-decoration: none;
}

#titul .zalozky .azalozka a:hover,
#titul .zalozky .azalozka a:link:hover,
#titul .zalozky .azalozka a:visited:hover,
#titul .zalozky .azalozka a:hover:hover,
#titul .zalozky .azalozka a:focus:hover,
#titul .zalozky .azalozka a:active:hover {
  text-decoration: none;
}

#titul .zalozky .azalozka a::after,
#titul .zalozky .azalozka a:link::after,
#titul .zalozky .azalozka a:visited::after,
#titul .zalozky .azalozka a:hover::after,
#titul .zalozky .azalozka a:focus::after,
#titul .zalozky .azalozka a:active::after {
  content: "";
  width: 40px;
  height: 16px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: 0;
  background: url(images/triangleRm.png) no-repeat 0 0;
}

@media screen and (max-width: 649px) {
  #linkynakotvy {
    display: none;
  }
  #titul .poz h2.cist {
    position: static;
    width: auto;
    height: auto;
    color: #333333;
    font-size: 2rem;
    text-align: center;
    padding: 0 0 12px;
    font-weight: 600;
  }
  #titul .poz .skryty {
    display: block;
  }
}

/******************
* MODULY
******************/
#uvodtitul {
  overflow: hidden;
  margin: 0 0 30px 0;
}

#obalUpozorneni {
  padding: 9px 20px;
  background-color: rgba(0, 0, 0, 0.6);
  width: 100%;
  max-width: 1420px;
  text-align: center;
  z-index: 10;
  box-sizing: border-box;
}

@media screen and (min-width: 601px) {
  #obalUpozorneni {
    position: absolute;
    top: 0;
    left: 50%;
    transform: translateX(-50%);
  }
}

@media screen and (min-width: 1421px) {
  #obalUpozorneni {
    top: 30px;
  }
}

#upozorneni,
#upozorneni h2.cvi,
#upozorneni a, #upozorneni a:link, #upozorneni a:visited {
  color: white;
}

#upozorneni h2.cvi,
#upozorneni p {
  display: inline-block;
  font-size: 1.0625rem;
  vertical-align: middle;
}

#upozorneni h2.cvi {
  font-weight: bold;
  margin: 0;
  position: relative;
  top: -20px;
}

#upozorneni h2.cvi span {
  display: none;
}

@media screen and (min-width: 501px) {
  #upozorneni h2.cvi::before {
    content: '';
    width: 65px;
    height: 56px;
    margin-right: 18px;
    position: relative;
    top: 20px;
    background: url(images/pozor.png) no-repeat 0 0;
    display: inline-block;
  }
}

#upozorneni p {
  max-width: calc(100% - 90px);
}

@media screen and (min-width: 801px) {
  #upozorneni h2.cvi {
    margin: 0 20px 0 0;
  }
  #upozorneni h2.cvi span {
    display: inline;
  }
  #upozorneni p {
    max-width: calc(100% - 210px);
  }
}

.aktuality {
  background-color: #be0000;
  padding: 0 0 60px;
}

.aktuality,
.aktuality h2.cvi,
.aktuality a {
  color: white;
}

.aktuality h2.cvi {
  margin: 0 0 60px;
  text-align: center;
  font-size: 2.5rem;
  font-weight: 500;
  position: relative;
  z-index: 12;
  padding: 32px 0 0;
}

@media screen and (min-width: 601px) {
  .aktuality h2.cvi {
    margin: -20px 0 80px;
    padding: 8px 0;
  }
}

.aktuality h2.cvi::after {
  content: '';
  width: 40px;
  height: 16px;
  position: absolute;
  left: 50%;
  margin-left: -20px;
  bottom: -26px;
  background: url(images/triangleWm.png) no-repeat 0 0;
}

.temata ul {
  padding: 0 0 20px;
  margin: -25px 0;
}

.temata li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  text-align: left;
  margin-top: 25px;
  margin-bottom: 25px;
}

@media screen and (max-width: 499px) {
  .temata li {
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 799px) {
  .temata li {
    width: 48.5%;
    margin-left: 3%;
  }
  .temata li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 800px) and (max-width: 1099px) {
  .temata li {
    width: 30.66667%;
    margin-left: 4%;
  }
  .temata li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1100px) {
  .temata li {
    width: 21.25%;
    margin-left: 5%;
  }
  .temata li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

.temata li strong a {
  color: #be0000;
  font-size: 1.25rem;
  font-weight: 600;
}

@media screen and (min-width: 1001px) {
  .temata li strong a {
    font-size: 1.4375rem;
  }
}

.temata li div {
  margin: 0;
  padding: 10px 0 0;
  font-size: 1.0625rem;
  font-weight: 300;
  color: #626262;
}

.banneryPraha {
  background: url(images/bannery/bg.jpg) no-repeat center top;
  background-size: cover;
}

.banneryPraha .centrovany {
  display: flex;
}

.banneryPraha ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-self: center;
  width: 100%;
  max-width: 380px;
  margin: 24px auto;
}

.banneryPraha li {
  background: white;
  padding: 18px 3.2%;
  box-sizing: border-box;
  position: relative;
  margin: 12px auto;
}

.banneryPraha li a::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

@media screen and (min-width: 651px) {
  .banneryPraha .centrovany {
    max-width: 584px;
    height: 371px;
  }
  .banneryPraha ul {
    max-width: 100%;
    margin: 0;
  }
  .banneryPraha li {
    margin: 0;
    padding: 18px 1.2%;
  }
  .banneryPraha .prahaEu,
  .banneryPraha .visitPraha {
    padding: 18px 3.4%;
    margin-bottom: 24px;
  }
}

@media screen and (min-width: 1301px) {
  .banneryPraha .centrovany {
    max-width: 1420px;
  }
  .banneryPraha li {
    padding: 18px 1.2%;
  }
  .banneryPraha .prahaEu,
  .banneryPraha .visitPraha {
    padding: 18px 1.74%;
    margin-bottom: 0;
  }
}

@media screen and (min-width: 1421px) {
  .banneryPraha li {
    padding: 21px 2%;
  }
  .banneryPraha .prahaEu,
  .banneryPraha .visitPraha {
    padding: 21px 2.9%;
  }
}

#kalendarAkci .editor {
  margin-top: 40px;
  margin-bottom: 40px;
}

#obalmbannery {
  min-width: 320px;
  background: #ffffff;
  border-top: 1px solid #626262;
}

#mbannery {
  padding: 30px 0;
  color: #000000;
  margin: 0;
}

#mbannery .editor {
  text-align: center;
}

#mbannery .editor a,
#mbannery .editor a:link,
#mbannery .editor a:visited,
#mbannery .editor a:hover,
#mbannery .editor a:focus,
#mbannery .editor a:active {
  color: #000000 !important;
}

#mbannery li {
  margin-top: 0;
  margin-bottom: 0;
}

#jmeniny {
  padding: 6px 12px 2px 15px;
  text-align: left;
}

#jmeniny * {
  display: inline;
}

.kontaktUradu {
  color: #626262;
  font-size: 1rem;
  font-weight: 300;
  text-align: center;
  padding: 3px 0 2px;
}

@media screen and (min-width: 601px) {
  .kontaktUradu {
    float: left;
    text-align: left;
  }
}

.kontaktUradu strong {
  display: none;
}

.kontaktUradu .udajRadek {
  padding: 0 16px;
}

.kontaktUradu .podnadpis-ku {
  padding-right: 16px;
}

.odkazAbo,
.odkazRss,
.fullscreen-search-anchor {
  display: inline-block;
  vertical-align: top;
  margin-top: -5px;
  color: #626262;
}

.odkazAbo img,
.odkazRss img,
.fullscreen-search-anchor img {
  position: relative;
  top: 4px;
  margin-left: 7px;
}

#kontakt {
  color: #626262;
  font-size: 1rem;
  clear: both;
}

@media screen and (min-width: 1201px) {
  #kontakt {
    font-size: 1.0625rem;
  }
}

#kontakt address {
  display: block;
  font-style: normal;
  margin: 0;
  line-height: 1.6;
}

#kontakt strong {
  font-weight: bold;
}

#kontakt .udajRadek {
  display: block;
}

#kontakt .mail-druhy::before,
#kontakt .tel-druhy::before {
  display: inline;
  font-weight: bold;
  color: transparent !important;
}

#kontakt .mail-druhy::before {
  content: 'E-mail: ';
}

@media screen and (min-width: 1001px) and (max-width: 1100px) {
  #kontakt .mail-druhy::before {
    content: normal;
  }
}

#kontakt .tel-druhy:before {
  content: 'Tel.: ';
}

#kontakt a.podrobny {
  display: inline-block;
  margin: 25px 0 0;
  padding: 0;
  text-decoration: underline;
  text-transform: uppercase;
  font-weight: bold;
}

#kontakt a.podrobny:hover {
  text-decoration: none;
}

#kontakt .podatelna {
  line-height: 1.6;
}

#kontakt .podatelna .zvyrazneni {
  font-size: 1.0625rem !important;
  color: #626262;
}

#kontakt .podatelna table {
  width: 100%;
  max-width: 300px;
}

#kontakt .podatelna table td {
  vertical-align: top;
}

#kontakt .podatelna p {
  margin: 0;
}

#titul #kontakt .podatelna {
  display: block;
  padding-top: 24px;
}

@media screen and (min-width: 601px) {
  #titul #kontakt .uradmc {
    float: left;
    width: 49%;
  }
  #titul #kontakt .podatelna {
    float: right;
    width: 49%;
    padding-top: 0;
  }
}

@media screen and (min-width: 1001px) {
  #titul #kontakt {
    clear: none;
  }
  #titul #kontakt .uradmc {
    float: left;
    width: 45%;
  }
  #titul #kontakt .podatelna {
    float: right;
    width: 53%;
  }
}

#stranka #kontakt .kontaktTretina {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 800px) {
  #stranka #kontakt .kontaktTretina {
    width: 100%;
  }
}

@media screen and (min-width: 801px) {
  #stranka #kontakt .kontaktTretina {
    width: 31.66667%;
    margin-left: 2.5%;
  }
  #stranka #kontakt .kontaktTretina:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

#stranka #kontakt .podatelna {
  display: block;
  padding-top: 24px;
}

@media screen and (min-width: 801px) {
  #stranka #kontakt .podatelna {
    padding: 0;
  }
}

#anketa p {
  padding: 0;
  margin: 0 0 1em 0;
}

#anketa ul {
  list-style-type: none;
  padding: 0;
  margin: 8px 0 5px 0;
}

#anketa ul li {
  margin-bottom: 8px;
}

#anketa .hlas {
  margin-top: 3px;
  z-index: 1;
}

#anketa .hlas div {
  height: 12px;
  background-color: #228be6;
}

#pocitadlo {
  margin: 15px 0 0 0;
  text-align: center;
  padding-bottom: 15px;
}

#pocitadlo ul.inline {
  padding-left: 0;
  display: inline;
}

#pocitadlo ul.inline li span {
  margin: 0 0 0 2px;
}

@media screen and (max-width: 599px) {
  #pocitadlo li {
    display: block !important;
  }
  #pocitadlo li + li::before {
    content: '';
    margin: 0;
  }
}

/*******************************
* KALENDÁŘE
*******************************/
.mesice {
  display: flex;
  justify-content: space-between;
  color: #2a2a2a;
}

#stranka .mesice .c-actual-month {
  text-align: center;
}

#kalakci {
  max-width: 30em;
  width: 104%;
  margin-left: -2%;
}

@media screen and (min-width: 451px) {
  #kalakci {
    width: 106%;
    margin-left: -3%;
  }
}

#kalakci .calcells {
  width: 100%;
  border-spacing: 0 1.5em;
  font-size: 0.875rem;
}

#kalakci .calcells td,
#kalakci .calcells th {
  box-sizing: border-box;
  position: relative;
}

#kalakci .calcells th {
  top: -6px;
  color: #2a2a2a;
}

#kalakci .calcells td {
  width: 14.28%;
  height: 2em;
  text-align: center;
  font-size: 0.875rem;
  z-index: 0;
}

#kalakci .calcells td::before {
  content: "";
  position: absolute;
  display: block;
  width: 2em;
  height: 2em;
  border-radius: 500em;
  position: absolute;
  left: 50%;
  top: 50%;
  margin: -1em 0 0 -1em;
  box-sizing: border-box;
  z-index: -1;
}

#kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}

#kalakci .calcells td.wkday, #kalakci .calcells td.wkday a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.curdate::before {
  background-color: magenta;
}

#kalakci .calcells td.curdate, #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#kalakci .calcells td.curdate:hover, #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px blue solid;
}

#kalakci .calcells td.notmnth, #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
  text-decoration: none;
}

#kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
}

#kalakci .calcells td a {
  line-height: 2em;
  position: absolute;
  width: 2em;
  left: 50%;
  margin-left: -1em;
  top: 0;
  bottom: 0;
  font-size: 0.875rem;
}

#kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
  text-decoration: underline;
  border-radius: 500em;
}

#kalakci .calcells td a > span {
  font-weight: normal;
  font-size: 0.75rem;
  position: absolute;
  top: -.9em;
  right: -.4em;
  width: 1.6em;
  height: 1.6em;
  line-height: 1.7em;
  background-color: yellow;
  border: 0px transparent solid;
  color: #2a2a2a;
  border-radius: 500em;
}

@media screen and (min-width: 451px) {
  #kalakci .calcells td {
    height: 2.5em;
  }
  #kalakci .calcells td::before {
    width: 2.5em;
    height: 2.5em;
    margin: -1.25em 0 0 -1.25em;
  }
  #kalakci .calcells td a {
    line-height: 2.5em;
    width: 2.5em;
    margin-left: -1.25em;
  }
  #kalakci .calcells td a > span {
    right: -.7em;
  }
}

@media screen and (min-width: 601px) {
  #kalakci .calcells {
    font-size: 1rem;
  }
  #kalakci .calcells td, #kalakci .calcells td a {
    font-size: 1rem;
  }
  #kalakci .calcells td a > span {
    font-size: 0.875rem;
  }
}

@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  #kalakci .calcells td {
    line-height: 2em;
  }
  #kalakci .calcells td::before {
    margin-top: 0;
    top: 0;
  }
  #kalakci .calcells td a {
    bottom: auto;
  }
}

.akce-podle-data {
  clear: both;
}

#stranka #kalakci {
  margin-top: 10px;
  margin-bottom: 25px;
  max-width: 41.25em;
  float: left;
}

#stranka #kalakci .c-arrow {
  display: inline-block;
}

#stranka #kalakci .c-arrow:hover {
  text-decoration: underline;
}

#stranka #kalakci .c-before-month, #stranka #kalakci .c-next-month {
  padding: 0;
}

#stranka #kalakci .c-before-month .space, #stranka #kalakci .c-before-month .c-moth, #stranka #kalakci .c-before-month .c-year, #stranka #kalakci .c-next-month .space, #stranka #kalakci .c-next-month .c-moth, #stranka #kalakci .c-next-month .c-year {
  display: none;
}

#stranka #kalakci .c-actual-month {
  padding-left: 0;
  text-align: center;
}

#stranka #kalakci .mesice {
  margin: 0 auto;
  display: table;
  width: 100%;
  line-height: .8;
  table-layout: fixed;
  border-spacing: 1px 0;
  margin-bottom: 10px;
  font-size: 1.25rem;
}

#stranka #kalakci .mesice .c-actual-month {
  display: table-cell;
  width: 71%;
  vertical-align: bottom;
}

#stranka #kalakci .mesice + p {
  margin: 20px 0;
}

#stranka #kalakci .mesice a {
  display: table-cell;
  vertical-align: bottom;
  text-decoration: underline;
  color: #232323;
  text-align: center;
}

#stranka #kalakci .mesice a:hover {
  text-decoration: none !important;
}

#stranka #kalakci .calcells td.wkday::before {
  background-color: transparent;
  border: 2px blue solid;
}

#stranka #kalakci .calcells td.wkday, #stranka #kalakci .calcells td.wkday a {
  color: #2a2a2a;
}

#stranka #kalakci .calcells td.curdate::before {
  background-color: magenta;
}

#stranka #kalakci .calcells td.curdate, #stranka #kalakci .calcells td.curdate a {
  color: #ffffff;
}

#stranka #kalakci .calcells td.curdate:hover, #stranka #kalakci .calcells td.curdate a:hover {
  color: #ffffff !important;
}

#stranka #kalakci .calcells td.notmnth::before {
  background-color: transparent;
  border: 1px magenta solid;
}

#stranka #kalakci .calcells td.notmnth, #stranka #kalakci .calcells td.notmnth a {
  color: #2a2a2a;
}

#stranka #kalakci .calcells td.notmnth a > span {
  background-color: yellow;
  border: 1px gold solid;
  color: #2a2a2a;
}

#stranka #kalakci .calcells td a:hover {
  background-color: transparent !important;
  color: #2a2a2a !important;
}

#stranka #kalakci .calcells td a > span {
  background-color: yellow;
  border: 1px gold solid;
  color: #2a2a2a;
}

/*****************************************
* MIXINY A PROMĚNNÉ PRO TABULKOVÉ MENU
*****************************************/
/****************************************/
/******************************
* HORIZONTÁLNÍ MENU A VÝBĚRY
******************************/
#obalmenu1 {
  position: relative;
  z-index: 15;
  display: inline-block;
  vertical-align: middle;
  text-align: center;
  width: 100%;
  padding: 10px 0 16px;
}

@media screen and (min-width: 1201px) {
  #obalmenu1 {
    width: calc(100% - 400px);
    text-align: right;
    padding: 0;
  }
}

@media screen and (min-width: 1201px) {
  #menu1 ul {
    margin: 0 -16px;
  }
}

@media screen and (min-width: 1301px) {
  #menu1 ul {
    margin: 0 -30px;
  }
}

#menu1 li {
  font-size: 1.1875rem;
  line-height: 1.1;
  display: block;
  margin: 0;
  padding: 0;
}

#menu1 li a {
  display: block;
  padding: 8px;
}

#menu1 li.akt a {
  color: #be0000;
}

#menu1 li a,
#menu1 li a:link,
#menu1 li a:visited {
  text-decoration: none;
  color: #303030;
  font-weight: 600;
}

#menu1 li a:hover,
#menu1 li a:focus,
#menu1 li a:active {
  text-decoration: underline;
  color: #be0000;
}

#menu1 li #osmakth,
#menu1 li #osmakth:link,
#menu1 li #osmakth:visited,
#menu1 li #osmakth:hover,
#menu1 li #osmakth:focus,
#menu1 li #osmakth:active {
  cursor: text;
  text-decoration: none;
}

@media screen and (min-width: 601px) {
  #menu1 li {
    display: inline-block;
    padding: 0 10px;
  }
  #menu1 li div {
    display: inline;
  }
}

@media screen and (min-width: 1301px) {
  #menu1 li a {
    padding: 5px 20px;
  }
}

#stranka.noleftpanel #telo {
  max-width: 100%;
}

#stranka.noleftpanel #telo #stred {
  width: 100%;
  float: none;
  clear: both;
  padding: 0 0 44px !important;
  margin: 0;
}

#stranka.noleftpanel #telo #stred #zahlavi h2.cvi {
  margin: 30px 0 10px;
}

@media screen and (min-width: 801px) {
  #stranka.noleftpanel #telo #stred #zahlavi h2.cvi {
    margin: 60px 0 16px;
  }
}

#stranka.noleftpanel #menu-start {
  display: none;
}

#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici {
  position: relative;
  margin-bottom: 44px;
}

#stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
}

@media screen and (max-width: 499px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 899px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 50%;
    margin-left: 0%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 900px) and (max-width: 1199px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 33.33333%;
    margin-left: 0%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1200px) {
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 25%;
    margin-left: 0%;
  }
  #stranka.noleftpanel .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(4n+1) {
    margin-left: 0;
    clear: left;
  }
}

#osnova * {
  font-style: normal;
}

.mapa-stranek-2016 h3.souvisejiciodkazy {
  display: none;
}

.mapa-stranek-2016 .editor {
  margin-top: 30px;
  max-width: 100%;
}

.mapa-stranek-2016 .odkazy.souvisejici {
  overflow: hidden;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui {
  display: flex;
  flex-wrap: wrap;
  margin: -2px -15px 0;
  padding: 0;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
  /****************
	* ZÁKLADNÍ STYLY
	****************/
  float: left;
  /****************
	* /ZÁKLADNÍ STYLY
	****************/
  margin-top: 0;
  margin-bottom: 15px;
  padding: 0 15px 24px;
  box-sizing: border-box;
}

@media screen and (max-width: 499px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 100%;
  }
}

@media screen and (min-width: 500px) and (max-width: 1099px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 50%;
    margin-left: 0%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(2n+1) {
    margin-left: 0;
    clear: left;
  }
}

@media screen and (min-width: 1100px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li {
    width: 33.33333%;
    margin-left: 0%;
  }
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li:nth-of-type(3n+1) {
    margin-left: 0;
    clear: left;
  }
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong {
  display: block;
  border-top: 1px solid #cccccc;
  padding: 30px 0 0;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a {
  color: #be0000 !important;
  font-size: 1.1875rem;
  font-weight: 500;
  text-decoration: underline;
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a:hover {
  text-decoration: none;
}

@media screen and (min-width: 951px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a {
    font-size: 1.3125rem;
  }
}

@media screen and (min-width: 1201px) {
  .mapa-stranek-2016 .odkazy.souvisejici ul.ui li strong a {
    font-size: 1.4375rem;
  }
}

.mapa-stranek-2016 .odkazy.souvisejici ul.ui li div {
  color: #626262;
  font-size: 1.0625rem;
  padding: 10px 0 0;
}

.mapa-stranek-2016 .pataprvku, .mapa-stranek-2016 .popis {
  margin: 30px 0;
  box-sizing: border-box;
  font-size: 1.0625rem;
  max-width: 750px;
}

.mapa-stranek-2016 .pataprvku {
  padding-top: 30px;
  border-top: 1px solid #dfdfdf;
}

/***********************
	*** ROZBALOVACÍ MENU ***
	***********************/
.menu-controls,
#menu-start {
  display: none;
}

.nadmenu,
#nadmenu {
  display: none;
}

/**********************
	* MOBILNÍ MENU
	**********************/
#menu {
  padding: 20px;
  clear: both;
}

#menu > ul a {
  padding: 5px 20px;
  margin-left: -20px;
  display: block;
}

#menu > ul a,
#menu > ul a:link,
#menu > ul a:visited {
  color: #303030;
}

#menu > ul #osmakt,
#menu > ul #osmakt:link,
#menu > ul #osmakt:visited,
#menu > ul #osmakt:hover,
#menu > ul #osmakt:focus,
#menu > ul #osmakt:active {
  background-color: transparent;
  color: #be0000;
  text-decoration: none;
}

#menu ul {
  padding-left: 20px;
}

@media screen and (max-width: 800px) {
  #menu {
    background-color: #f2f2f2;
  }
  #menu-start {
    display: block;
    clear: both;
    text-align: center;
  }
  #menu-start a {
    background: #f2f2f2;
    display: block;
    padding: 15px 0;
  }
  #menu-start a::before {
    display: inline-block;
    vertical-align: middle;
    margin: -.15em .75em 0 0;
    content: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='30' height='22' viewBox='0 0 30 22'%3E%3Cpath fill='%23303030' fill-rule='evenodd' clip-rule='evenodd' d='M29 22h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1zm0-9h-28c-.552 0-1-.448-1-1v-2c0-.552.448-1 1-1h28c.552 0 1 .448 1 1v2c0 .552-.448 1-1 1z'/%3E%3C/svg%3E");
  }
  #menu-start a,
  #menu-start a:link,
  #menu-start a:visited {
    color: #303030;
    text-decoration: none;
  }
  .no-scroll {
    height: 100%;
    touch-action: none;
    overflow: hidden;
    pointer-events: none;
    -webkit-overflow-scrolling: auto;
  }
  .no-scroll body {
    height: 100%;
    touch-action: none;
    overflow: hidden;
  }
  #menu.menu-initialised {
    pointer-events: auto;
    box-sizing: border-box;
    z-index: 200;
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    height: 100%;
    max-width: 400px;
    height: 100vh;
    padding: 70px 20px 20px 0;
    transition: transform .3s ease-out;
    -webkit-transform: translateX(-100%);
    transform: translateX(-100%);
    opacity: 0;
    -webkit-transition: -webkit-transform .3s ease-out;
  }
  [data-env="Safari"] #menu.menu-initialised {
    max-width: 100%;
  }
  #menu.menu-initialised.menu-active {
    -webkit-transform: translateX(0);
    transform: translateX(0);
    opacity: 1;
  }
  #menu.menu-initialised > ul {
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0 0 0 20px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    z-index: 101;
  }
  #menu.menu-initialised > ul > li {
    margin-bottom: 15px;
  }
  #menu.menu-initialised > ul > li:last-child {
    margin-bottom: 80px;
  }
  #menu.menu-initialised ul {
    list-style-type: none;
  }
  #menu.menu-initialised .menu-controls {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    display: block;
    height: 50px;
    z-index: 100;
    background-color: #be0000;
    color: #ffffff;
  }
  #menu.menu-initialised .menu-controls .menu-control-back,
  #menu.menu-initialised .menu-controls .menu-control-title {
    display: block;
  }
  #menu.menu-initialised .menu-controls .menu-control-back {
    position: absolute;
    width: 50px;
    height: 50px;
    right: 0;
    top: 0;
    background-image: url("data:image/svg+xml;charset=utf8,%3Csvg width='22' height='22' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath fill='%23ffffff' fill-rule='evenodd' d='M13.569 10.994l8.162 8.165c.355.358.355.936 0 1.294l-1.28 1.279c-.357.358-.935.358-1.293 0l-8.16-8.166-8.162 8.166c-.357.358-.935.358-1.292 0l-1.278-1.279c-.357-.358-.357-.936 0-1.294l8.162-8.165-8.152-8.154c-.357-.357-.357-.935 0-1.292l1.279-1.28c.356-.357.935-.357 1.292 0l8.151 8.154 8.152-8.154c.357-.357.935-.357 1.293 0l1.276 1.28c.359.357.359.935 0 1.292l-8.15 8.154z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 22px 22px;
  }
  #menu.menu-initialised .menu-controls .menu-control-title {
    padding: 0 20px;
    line-height: 2.2;
    border-right: 70px transparent solid;
    white-space: nowrap;
    font-size: 1.25rem;
    -ms-text-overflow: ellipsis;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  #menu.menu-initialised .menu-indicator {
    display: none;
  }
  #menu.menu-initialised .akt a {
    font-weight: bold;
    text-decoration: none;
  }
}

/***********************
	* DESKTOPOVÉ MENU
	***********************/
@media screen and (min-width: 801px) {
  .nadmenu {
    display: block;
  }
  .nadmenu a {
    margin: 64px 0 20px;
    position: relative;
    color: #be0000;
    font-size: 1.375rem;
    padding: 0 16px 0 20px;
    display: block;
    text-decoration: none;
    font-weight: 700;
    line-height: 1.15;
  }
}

@media screen and (min-width: 801px) and (min-width: 1001px) {
  .nadmenu a {
    margin: 84px 0 24px;
    font-size: 1.5625rem;
    padding: 0 16px 0 32px;
  }
}

@media screen and (min-width: 801px) and (min-width: 1201px) {
  .nadmenu a {
    margin: 104px 0 32px;
    font-size: 1.75rem;
  }
}

@media screen and (min-width: 801px) {
  .nadmenu a:hover {
    text-decoration: underline;
  }
  .nadmenu a::before {
    content: "";
    position: absolute;
    left: 0;
    top: 50%;
    margin-top: -10px;
    display: inline-block;
    width: 9px;
    height: 19px;
    background: url(images/sipkaZpet.png) no-repeat 0 0;
  }
  #nadmenu {
    font-size: 0.875rem;
    display: none;
    font-weight: normal;
    padding: 0;
    white-space: nowrap;
  }
  #menu {
    padding: 0;
  }
  #menu h2.cvi {
    background-color: #228be6;
    padding: 10px 25px 10px 40px;
    font-size: 1.75rem;
    color: #000000;
    font-weight: normal;
  }
  #menu .menu-item-collapsed > ul {
    display: none;
  }
  #menu .menu-item-expanded > a .menu-indicator {
    transform: rotate(225deg);
  }
  #menu .menu-item-expanded > ul {
    display: block;
  }
  #menu .menu {
    background-color: #f2f2f2;
    font-size: 1.125rem;
    line-height: 1.2;
    padding: 38px 16px 90px 0;
    font-weight: 300;
    position: relative;
  }
  #menu .menu::before {
    content: "";
    position: absolute;
    width: 250px;
    right: 100%;
    top: 0;
    bottom: 0;
    background: #f2f2f2;
  }
}

@media screen and (min-width: 801px) and (min-width: 1201px) {
  #menu .menu {
    font-size: 1.25rem;
  }
}

@media screen and (min-width: 801px) {
  #menu .menu ul {
    text-transform: none;
  }
  #menu .menu a,
  #menu .menu a:link,
  #menu .menu a:visited {
    color: #303030;
    text-decoration: none;
  }
  #menu .menu a:hover,
  #menu .menu a:link:hover,
  #menu .menu a:visited:hover {
    text-decoration: underline;
  }
  #menu .menu ul a:hover,
  #menu .menu ul a:focus,
  #menu .menu ul a:active {
    text-decoration: underline;
  }
  #menu .menu #osmakt,
  #menu .menu #osmakt:link,
  #menu .menu #osmakt:visited,
  #menu .menu #osmakt:hover,
  #menu .menu #osmakt:focus,
  #menu .menu #osmakt:active {
    font-weight: bold;
    background-color: transparent;
    text-decoration: none;
    cursor: default;
    border: 0;
  }
  #menu .menu a {
    padding: 10px 0;
    display: block;
    position: relative;
    z-index: 10;
    margin-left: 0;
    text-decoration: none;
  }
  #menu .menu .akt a {
    font-weight: bold;
  }
  #menu .menu .akt a:hover {
    text-decoration: none;
  }
  #menu .menu .menu-indicator {
    width: 16px;
    height: 16px;
    overflow: hidden;
    position: absolute;
    top: .85em;
    left: -27px;
    z-index: 10;
    -webkit-transition: -webkit-transform .25s;
    transition: -ms-transform .25s;
    transition: transform .25s;
  }
  #menu .menu .menu-indicator img {
    margin: 0;
    cursor: pointer;
    font-size: 0.875rem;
  }
  #menu .menu ul {
    font-size: 1rem;
    margin: 0 0 0 -40px;
    padding: 2px 0 12px 0;
  }
  #menu .menu ul a {
    padding: 1px 15px 0 40px;
    line-height: 1.5;
  }
  #menu .menu ul .menu-indicator {
    left: 13px;
    top: .23em;
  }
  #menu .menu ul ul {
    margin: 0;
    padding: 0 0 0 24px;
    position: static;
  }
  #menu li {
    margin: 0;
    padding: 0;
    position: relative;
    display: block;
  }
  #menu li li {
    border: 0;
    font-weight: normal;
  }
}

#google_translate_element {
  display: inline-block;
  position: relative;
  top: 2px;
  vertical-align: top;
  padding: 0 20px;
}

#google_translate_element.pole {
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple {
  border: 0;
  width: 100%;
  padding-bottom: 1px;
  background-color: transparent;
}

#google_translate_element .goog-te-gadget-simple,
#google_translate_element .goog-te-gadget-simple * {
  font-size: 1rem !important;
}

#google_translate_element .goog-te-gadget-simple .goog-te-gadget-icon {
  float: right;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] {
  margin-right: 0;
  display: inline-block;
  text-decoration: none !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(1) {
  padding: 0 3px;
  display: inline-block;
  color: #626262;
  text-decoration: underline;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(1):hover {
  text-decoration: none;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(2) {
  padding-right: 3px;
  border-left: 0 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] span:nth-of-type(3) {
  display: none;
  color: #000000 !important;
}

#google_translate_element .goog-te-gadget-simple [aria-haspopup][href='#'] img {
  display: none;
}

@media screen and (max-width: 600px) {
  #google_translate_element,
  #goog-gt-tt,
  #goog-gt-tt + div > object,
  iframe.goog-te-menu-frame,
  iframe.goog-te-banner-frame {
    display: none !important;
  }
}
