
/**************************
* OBECNÉ
**************************/

html {
	touch-action: manipulation;
}

html,
body {
	background-color: #ffffff;
}

body {
	@extend %reset;
	text-align: center;
}

@include selection {
	background-color: $color-main;
	color: #ffffff;
}

a:not([href^="#"]):not([href*="javascript:void"]):not(.patalogo):not(.expandable):not(.menu-link):not(.mouse-focus):focus {
	border-radius: 3px;
	box-shadow: 0 0 0 2000px $link-focus-bg inset, 0 0 0 3px $link-focus-bg !important;
	color: $link-focus-text !important;
	outline: 0;
}

img {
	color: inherit;
}


/*************************
* HLAVNÍ OBSAH
*************************/

.centrovany {
	@extend %sf;
	width: 94%;
	max-width: $max-width;
	margin: 0 auto;
	text-align: left;
}

 
#stred,
#vpravo,
#vlevo {
	@extend %border-box;
	width: 100%;

	@include bp("> 800px"){
		margin-bottom: 110px;
	}
}

#vpravo,
#vlevo {
	word-wrap: break-word;
}

#stranka #stred {
	padding: 50px 0 0;

	.stredUzsi{
		max-width: 750px;
		float: left;
		width: 100%;
		padding-bottom: 60px;

		@include bp("> 800px"){
			padding-bottom: 0;
		}
	
	}

	&.sirsi-stred .stredUzsi{
		max-width: 100%;
	}
}

#stranka #obalcelek{
	max-width: 1920px;
	margin: 0 auto;
	overflow: hidden;
}

/*************************************
* FLEX LAYOUT HLAVNÍCH SLOUPCŮ
*************************************/

@include bp("> 800px") {
	#telo {
		display: flex;
		justify-content: space-between;
	}

	#stred {
		width: ratio($max-width, 902px);
		order: 1;
	}

	#vlevo {
		width: ratio($max-width, 432px);
		position: relative;
		
		&::before{
			content: "";
			position: absolute;
			right: 0;
			width: 800px;
			top: 250px;
			bottom: 0;
			background: #f2f2f2;

			@include bp("> 1000px"){
				top: 270px;
			}

			@include bp("> 1200px"){
				top: 290px;
			}
		}
	}
}


/************************
* PRVKY STRÁNKY
************************/

.cestaObal {
	background: $barH;
	font-size: rem(14px);
	padding: 26px 0 27px 0;
	margin: 0;
	position: relative;
	color: white;

	strong {
		display: none;
	}

	a{
		color: white;
	}

	.cesta{
		text-align: right;
	}
}

.box {
	padding: 0 15px 10px 15px;
	background: #cccccc;
	margin: 0 0 15px 0;
}

.dalsi {
	font-size: rem(16px);
	margin: 15px 0 30px 0; 
	padding: 35px 0 0 0;
	text-align: center;
	font-family: $font;
	clear: both;
	
	@include bp("> 1000px"){
		font-size: rem(18px);
	}

	#pozicovani &{
		padding: 10px 0 0;
	}

	a {
		display: inline-block;
		padding: 22px 25px;
		text-transform: uppercase;
		text-decoration: none;
		font-weight: 500;

		&:hover{
			text-decoration: underline;
		}

		#pozicovani &{
			background-color: $barH;
			color: white;
		}

		.aktuality &{
			background-color: white;
			color: $barH;
		}
	}
}


/************************
* ZÁPATÍ
************************/

#nadpatou {
	padding: 40px 0 80px;

	&,
	#{selector-link()} {
		color: #626262;
	}

	h2.cvi{
		color: $barH;
		font-size: rem(30px);
		font-weight: 600;
		margin: 0;
		padding: 40px 0 20px;
	}

	.odkazyDole .boxNp{
		@include grid(601px 2 2%);
	}

	@include bp("> 1000px"){
		.odkazyDole{
			width: 57%;
			float: left;
		}
	
		#kontakt {
			width: 42%;
			float: right;
		}
	}
}

// vysoka pata podstranka
.nadpatou{
	border-top: 1px solid #bbbbbb;
	padding: 20px 0 60px;

	@include bp("> 800px"){
		padding: 50px 0 90px;	
	}

	h2.cvi{
		color: $barH;
		font-size: rem(30px);
		font-weight: 600;
		margin: 0;
		padding: 40px 0 20px;
	}
}


#obalpata {
	background-color: $footer-background;
	min-width: 320px;
}

#pata {
	text-align: center;
	padding: 22px 16px 28px;
	color: $footer-text; 
	box-sizing: border-box;
	font-size: rem(14px);

	* {
		line-height: 2.2;
		border: 0;
	}

	li + li::before{
		content: "\|";
		margin: 0 8px 0 0;
	}

	.partWebmaster1::before{
		content: "\|";
		margin: 0 10px 0 0;
	}

	@include link {
		color: $footer-link;
		text-decoration: underline;
	}

	@include link-over {
		text-decoration: none;
	}
	
	.patalogo {
		padding: 1px 6px;
		margin-right: 11px;
	}

	#pocitadlo{
		margin: 0!important;
		padding-bottom: 0!important;
	}

	.webmaster,
	.inline {
		display: inline-block;
		margin: 0;
		padding: 0 0 0 3px;

		li {
			padding: 0 5px;

			a {
				margin: 0 0 0 1px;
			}
		}
	}

	.webmaster {
		display: block;

		#stranka &{
				margin-top: 2px;
			}

		.partWebmaster {
			display: inline-block;
			margin-left: 5px;
		}
	}

	ul.inline li {
		display: inline-block;
	}
}

@include bp("< 500px"){
	.partWebmaster1 {
		display: block!important;
		margin-top: 2px;

		&::before{
			content: ""!important;
			margin:0!important;
		}	
	}	
}

#unpobtekane {
	margin-left: 0 !important;
}


@if is-bit-set($web_bitmask, $BIT_SKRYT_KONTEXT) {
	.zobrazeno.kontext2 {
		display: none;
	}
}

@if is-bit-set($web_bitmask, $BIT_SKRYT_ZODPOVIDA) {
	.popis.dpopis {
		display: none;
	}
}