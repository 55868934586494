
/***********************
*	ZÁLOŽKY
***********************/

.zalozky {
	font-size: rem(20px);
	line-height: 1.1;
	font-family: $font;
	font-weight: normal;

	ul {
		@extend %sf;
		@extend %reset-list;
	}

	li {
		padding: 0;
		margin: 0 .5em .5em 0;
		float: left;
		position: relative;
		display: block;

		&.azalozka {
			background:transparent none;
		}
	}

	a {
		display: block;
		text-decoration: none;
		margin: 0;
		position: relative;
		z-index: 20;
		cursor: pointer;
		padding: if(nth($form-padding, 2) == 0, 10px 20px 12px, 10px nth($form-padding, 2) 12px);
	}

	@include link {
		background-color: $tab-sub-background;
		color: $tab-sub-text;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $tab-sub-selected-background;
			color: $tab-sub-selected-text;
			text-decoration: none;
			cursor: default;
		}
	}
}


#zahlavi2 {
	margin: 20px 0;
}


#zalozkynadpis {
	padding: 15px 0 0 0;
}

.vych_pol {
	font-size: rem(16px);
	text-align: right;
	padding: 0 0 10px 0;
	margin: 0 0 10px 0;
	text-transform: uppercase;
	
	strong {
		font-weight: normal;
	}
}


a[id*="k0"] {
	height: 0;
	overflow: hidden;
	line-height: 0;
	font-size: 0;
	color: transparent !important;
}

/****************************
*	S VYPNUTÝM JS NA TITULCE
****************************/

.bezjs {
	padding: 20px 0 15px 0;

	ul {
		@extend %reset-list;
	}

	.inline li::before {
		color: #404040;
	}
}

#keskryti {
	@extend %reset;
	display: inline;
}

/****************************
*	SE ZAPNTÝM JS NA TITULCE
****************************/

#titul .zalozky {
	line-height: 1.1;
	height: 288px;
	position: relative;
	background: #303030 url(images/bg_zalozky.jpg) no-repeat center top;

	ul{
		position: absolute;
		bottom: 0;
		left: 50%;
		transform: translateX(-50%);
		max-width: 1182px;
		width: 100%;
		margin: 0;
		padding: 0;
	}

	.inline li::before {
		content: normal;
	}

	li {
		@extend %reset;
		float: none;
		display: inline-block;
		margin: 0;
		padding: 0 8px;
		box-sizing: border-box;
		font-size: rem(24px);
		width: 33%;

		@include bp("> 800px"){
			padding: 0 16px;
			font-size: rem(30px);
		}
	}

	a {
		padding: 1.2em 16px;
	}

	@include link {
		background-color: $tab-background;
		color: $tab-text;
	}

	@include link-over {
		text-decoration: underline;
	}

	.azalozka {
		@include link-all {
			background-color: $tab-selected-background;
			color: $tab-selected-text;
			font-weight: 600;
			text-decoration: none;
			
			&:hover{
				text-decoration: none;
			}

			&::after{
				content: "";
				width: 40px;
				height: 16px;
				position: absolute;
				left: 50%;
				margin-left: -20px;
				bottom: 0;
				background: url(images/triangleRm.png) no-repeat 0 0;
			}
		}
	}
}

@include bp("< 650px") {
	#linkynakotvy {
		display: none;
	}

	#titul .poz {
		h2.cist {
			position: static;
			width: auto;
			height: auto;
			color: #333333;
			font-size: rem(32px);
			text-align: center;
			padding: 0 0 12px;
			font-weight: 600;
		}

		.skryty {
			display: block;
		}
	}
}
