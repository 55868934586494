
#google_translate_element {
	display: inline-block;
	position: relative;
	top: 2px;
	vertical-align: top;
	padding: 0 20px;

	&.pole {
		background-color: transparent;
	}

	.goog-te-gadget-simple {
		border: 0;
		width: 100%;
		padding-bottom: 1px;
		background-color: transparent;
		
		&,
		& * {
			font-size: rem(16px) !important;
		}

		.goog-te-gadget-icon{
			float: right;
		}
	
		[aria-haspopup][href='#'] {
			margin-right: 0;
			display: inline-block;
			text-decoration: none!important;

			span {
				&:nth-of-type(1) {
					padding: 0 3px;
					display: inline-block;
					color: #626262;
					text-decoration: underline;

					&:hover{
						text-decoration: none;
					}
				}
				
				&:nth-of-type(2) {
					padding-right: 3px;
					border-left: 0!important;
				}
				
				&:nth-of-type(3) {
					display: none;
					color: #000000!important;
				}
			}

			img {
				display: none;
			}
		}
	}
}

@include bp("< 601px") {
	#google_translate_element,
	#goog-gt-tt,
	#goog-gt-tt + div > object,
	iframe.goog-te-menu-frame,
	iframe.goog-te-banner-frame {
		display: none!important;
	}
}